<template>
  <v-dialog :model-value="modelValue" persistent width="560" @click:outside="close">
    <AppModalContent>
      <template #title>
        <div class="modal__title">
          <span>Confirmation required</span>
          <div @click="close" class="vf-icon-close vf-icon"></div>
        </div>
      </template>
      <template #content>
        <div class="modal__content">Are you sure you want to delete the edition?</div>
      </template>
      <template #actions>
        <div class="modal__actions">
          <button @click="close">Cancel</button>
          <button @click="process">Ok</button>
        </div>
      </template>
    </AppModalContent>
  </v-dialog>
</template>

<script lang="ts">
import Enums from '@/models/enums'

export default {
  data() {
    return {
      buttonType: Enums.ButtonEnum
    }
  },
  emits: ['update:modelValue', 'process'],
  props: ['label', 'type', 'modelValue'],
  methods: {
    close() {
      this.$emit('update:modelValue', false)
    },
    process() {
      this.$emit('process')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.modal {
  &__title {
    @include modal-title;
  }
  &__content {
    @include modal-content-column;
    align-items: center;
  }
  &__actions {
    @include modal-actions;
    > button {
      font-size: $font-size-m;
      text-transform: none;
      font-weight: $font-weight-normal;
      &:nth-child(2) {
        color: $medium-blue;
        margin-left: 4 * $default-spacer;
      }
      &:hover {
        background: transparent;
      }
    }
  }
}
</style>
