import {
  createRouter,
  createWebHashHistory,
  type RouteParams,
  type RouteRecordRaw
} from 'vue-router'

import { routesRecords, type AppRouteNames } from '@/router/routes'
import { Auth, initialized, account } from '@/services/AuthService'

export const routes: RouteRecordRaw[] = routesRecords

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: 'nav_item--active'
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!initialized.value) {
      await Auth.initialize()
    }

    // authorised
    if (account.value) {
      if (Auth.getHasPermission()) {
        await Auth.getToken()
        return next()
      } else {
        return next('/access-denied')
      }
    }

    // unauthorised
    try {
      await Auth.login()
      return next()
    } catch (err) {
      return next(false)
    }
  } else if (to.fullPath !== '/access-denied') {
    return next('/access-denied')
  } else {
    next()
  }
})

export function routerPush(
  name: AppRouteNames,
  params?: RouteParams
): ReturnType<typeof router.push> {
  return params === undefined ? router.push({ name }) : router.push({ name, params })
}
