<template>
  <div class="vf-js-tab-bar nav">
    <div class="vf-tab-bar nav-item-container" role="tablist">
      <template v-for="(navItem, index) in navItems" v-bind:key="navItem.id">
        <NavigationItem :index="index" :navItem="navItem"></NavigationItem>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineAsyncComponent } from 'vue'

import { EventType } from '@azure/msal-browser'

import { navItems } from '@/components/navigation/navigationItems'
import { useAuthStore } from '@/stores/authStore'
import { msalInstance } from '@/authConfig'

export default {
  data() {
    return {
      navItems: navItems,
      signin: 'https://microsoft.com',
      msalInstance: msalInstance,
      authStore: useAuthStore()
    }
  },
  components: {
    NavigationItem: defineAsyncComponent(
      () => import('@/components/navigation/AppNavigationItem.vue')
    )
  },
  methods: {
    async signIn() {
      msalInstance.addEventCallback((event: any) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
          msalInstance.setActiveAccount(event.payload.account)
        }
      })

      msalInstance.initialize().then(() =>
        msalInstance
          .handleRedirectPromise()
          .then((authResult: any) => {
            //TODO: uncomment when problems with auth
            // console.log(authResult?.accessToken)
            this.authStore.setAccessToken(authResult?.accessToken as string)

            const account = msalInstance.getActiveAccount()
            if (!account) {
              msalInstance.loginRedirect()
            }
          })
          .catch((err: any) => {
            console.log(err)
          })
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
$img-size: 20px;

.nav {
  justify-content: flex-start;
  > .nav-item-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    position: fixed;
    background-color: $ocean-blue;
    overflow-x: hidden;

    > img {
      width: $img-size;
      height: $img-size;
    }
  }
}
</style>
