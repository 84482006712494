import axios from 'axios'

import { CONFIG } from '@/config'
import type { Team } from '@/models/team'
import DataHelper from '@/helpers/DataHelper'

class TeamService {
  private baseUrl = `${CONFIG.VITE_API_URL}`

  async fetchTeamList(id: string): Promise<Team[]> {
    return (
      await axios.get<Team[]>(`${this.baseUrl}/Teams?editionId=${id}`, DataHelper.getAuthHeader())
    ).data
  }

  async deleteTeamById(id: string): Promise<boolean> {
    return await axios.delete(`${this.baseUrl}/Team?id=${id}`, DataHelper.getAuthHeader())
  }

  async fetchTeamById(id: string): Promise<Team> {
    return (await axios.get<Team>(`${this.baseUrl}/Team?id=${id}`, DataHelper.getAuthHeader())).data
  }

  async addTeam(data: FormData, selectedEdition: string) {
    let users = data.getAll('users')
    users = users.map((item: FormDataEntryValue) => JSON.parse(item as string))

    return await axios.post(
      `${this.baseUrl}/Team`,
      {
        name: data.get('name'),
        users: users,
        challengeId: selectedEdition
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: DataHelper.getAuthHeader().headers.Authorization
        }
      }
    )
  }

  async editTeam(data: FormData) {
    let users = data.getAll('users')
    users = users.map((item: FormDataEntryValue) => JSON.parse(item as string))

    return await axios.put(
      `${this.baseUrl}/Team`,
      {
        name: data.get('name'),
        users: users,
        id: data.get('id')
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: DataHelper.getAuthHeader().headers.Authorization
        }
      }
    )
  }
}

export default new TeamService()
