<template>
  <v-dialog
    :model-value="props.modelValue"
    persistent
    width="1000"
    height="810"
    @click:outside="close"
  >
    <AppModalContent>
      <template #title>
        <div class="modal__title">
          <span>Edit edition</span>
          <div @click="close" class="vf-icon-close vf-icon"></div>
        </div>
      </template>
      <template #content>
        <template v-if="challengeConfigurationStore.challengeConfigurationFetchByIdLoading">
          <AppSpinner></AppSpinner>
        </template>
        <template v-else>
          <div class="modal__content">
            <AppInput
              :disabled="isDisabled"
              class="modal__content--label"
              :label="'Name'"
              v-model="form.name"
              placeholder="Name"
              :readonly="false"
              :type="'input'"
            ></AppInput>
            <AppValidationErrors
              v-if="v$.name.$error"
              :errors="v$.name.$errors"
            ></AppValidationErrors>
            <div>
              <span>Duration</span>

              <div class="modal__content--from">
                <AppDateInput
                  :disabled="isDisabled"
                  @update:modelValue="onDateSelection"
                  :label="'From'"
                  placeholder="Date from"
                  :formatProp="'dd.MM.yyyy'"
                  v-model="form.start"
                  :icon="calendarIconUrl"
                  :min="new Date(new Date().setDate(new Date().getDate() + 1))"
                >
                </AppDateInput>

                <AppInput
                  class="modal__content--label modal__content--label--readonly"
                  :label="'To'"
                  :disabled="isDisabled"
                  placeholder="Date to"
                  :changeLabel="true"
                  v-model="form.end"
                  :type="'text'"
                  :readonly="false"
                  :icon="calendarIconUrl"
                ></AppInput>
              </div>
              <AppValidationErrors
                v-if="v$.start.$error"
                :errors="v$.start.$errors"
              ></AppValidationErrors>
              <AppValidationErrors
                v-if="v$.end.$error"
                :errors="v$.end.$errors"
              ></AppValidationErrors>
              <div class="errors" v-if="showDatesOverlapping && startFormDirty">
                <p>{{ Enums.FormMessages.DATE_OVERLAPPING }}</p>
              </div>
            </div>

            <AppTimeInput
              class="modal__content--clock"
              :label="'Start time'"
              :icon="clockIconUrl"
              :disabled="isDisabled"
              v-model="form.hour"
            >
            </AppTimeInput>
            <AppValidationErrors
              v-if="v$.hour.$error"
              :errors="v$.hour.$errors"
            ></AppValidationErrors>
            <div class="modal__content--title">
              <AppDragAndDropFile
                multiple
                :active="!isDisabled"
                :draggable="!isDisabled"
                :removeable="!isDisabled"
                :accept="'image/*'"
                :title="'Background images'"
                v-model="form.files"
              ></AppDragAndDropFile>
              <AppValidationErrors
                v-if="v$.files.$error"
                :errors="v$.files.$errors"
              ></AppValidationErrors>
            </div>
          </div>
        </template>
      </template>
      <template #actions>
        <div class="modal__actions" v-if="!isDisabled">
          <AppButton @on-click="process" :type="buttonType.PRIMARY" :label="'Save'"></AppButton>
          <AppButton @on-click="close" :type="buttonType.SECONDARY" :label="'Cancel'"></AppButton>
        </div>
      </template>
    </AppModalContent>
  </v-dialog>
</template>

<script setup lang="ts">
import { reactive, ref, watch } from 'vue'

import { required, helpers, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import Enums from '@/models/enums'
import { useChallengeConfigurationStore } from '@/stores/challengeConfigurationStore'
import DataHelper from '@/helpers/DataHelper'
import ChallengeConfigurationService from '@/services/ChallengeConfigurationService'
import type { ChallengeConfiguration, Time } from '@/models/challenges'
import calendarIconUrl from '@/assets/images/VF_calendar.svg'
import clockIconUrl from '@/assets/images/VF_clock.svg'

const emit = defineEmits(['update:modelValue', 'process'])
const getInitialFormData = () => ({
  id: String() as string,
  name: String() as string,
  start: {} as Date,
  end: String() as string,
  hour: { hours: 10, minutes: 0, seconds: 0 } as Time,
  files: [] as string[]
})

const challengeConfigurationStore = useChallengeConfigurationStore()
const buttonType = Enums.ButtonEnum
const startFormDirty = ref(false)
const showDatesOverlapping = ref(false)
const isDurationOccupied = (value: string) => {
  if (
    value &&
    new Date(value).getDate() ===
      new Date(challengeConfigurationStore.getSelectedChallenge?.start).getDate()
  ) {
    showDatesOverlapping.value = false
    return true
  } else {
    return ChallengeConfigurationService.checkEditChallengeConfigurationDateOccupation({
      start: new Date(value).toISOString(),
      end: new Date(form.end).toISOString(),
      id: challengeConfigurationStore.getSelectedChallenge?.id
    }).then((res: boolean) => {
      showDatesOverlapping.value = !res
      return res
    })
  }
}
const validations = {
  name: {
    required: helpers.withMessage(Enums.FormMessages.REQUIRED, required),
    minLength: helpers.withMessage(Enums.FormMessages.MIN_LENGTH, minLength(3))
  },
  start: {
    required: helpers.withMessage(Enums.FormMessages.REQUIRED, required),
    minValue(val: Date) {
      return helpers.withMessage(Enums.FormMessages.DATE_AFTER_TODAY, () => val > new Date())
    },
    maxValue(val: Date, edition: ChallengeConfiguration) {
      return helpers.withMessage(
        Enums.FormMessages.DATE_OVERLAPPING_DATES,
        () => new Date(edition.start) > val
      )
    },
    asyncValidator: helpers.withAsync(isDurationOccupied)
  },
  end: {
    minValue(val: Date, edition: ChallengeConfiguration) {
      return helpers.withMessage(
        Enums.FormMessages.DATE_AFTER_START,
        () => val > new Date(edition.start)
      )
    }
  },
  hour: {
    required: helpers.withMessage(Enums.FormMessages.REQUIRED, required)
  },
  files: {
    required: helpers.withMessage(Enums.FormMessages.MIN_LENGTH_1, required)
  }
}
const form = reactive(getInitialFormData())
const props = defineProps({
  modelValue: Boolean,
  isDisabled: Boolean
})
const v$ = useVuelidate(validations, form)

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue) {
      loadData()
    }
  }
)

watch(
  () => challengeConfigurationStore.challengeConfigurationFetchByIdLoading,
  () => {
    loadEdition()
  }
)

function loadEdition() {
  if (!challengeConfigurationStore.challengeConfigurationFetchByIdLoading) {
    const edition = challengeConfigurationStore.challengeConfigurationById
    const timeZone = new Date(edition.start).getTimezoneOffset()

    form.id = edition.id
    form.name = edition.name
    form.start = new Date(edition.start)
    form.end = edition.end
    form.hour = {
      hours: new Date(edition.start).getHours() - timeZone / 60,
      minutes: new Date(edition.start).getMinutes(),
      seconds: 0
    } as Time
    form.files = edition.images.map((item) => {
      return item.image
    })
  }
}

async function loadData() {
  const edition = challengeConfigurationStore.getSelectedChallenge

  if (
    challengeConfigurationStore.challengeConfigurationById &&
    challengeConfigurationStore.challengeConfigurationById.id === edition.id
  ) {
    loadEdition()
  } else {
    challengeConfigurationStore.fetchChallengeConfigurationById(edition.id)
  }
}

function close() {
  emit('update:modelValue', false)
  showDatesOverlapping.value = false
  startFormDirty.value = false
  Object.assign(form, getInitialFormData())
  v$.value.$reset()
}

function process() {
  v$.value.$validate()

  if (!v$.value.$error) {
    const start = new Date(form.start)
    start.setHours(form.hour.hours, form.hour.minutes)

    const formData = new FormData()
    formData.append('id', form.id)
    formData.append('name', form.name)
    formData.append('start', start.toISOString())
    formData.append('end', new Date(form.end).toISOString())
    form.files.forEach((element) => {
      formData.append('images[]', element)
    })

    emit('process', formData)
    close()
  }
}

function onDateSelection(value: Date) {
  const dateTo = DataHelper.addBusinessDaysToDate(value)
  startFormDirty.value = true
  form.end = DataHelper.formatDate(dateTo.toISOString(), 'yyyy-MM-dd')
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

:deep(input[type='date']:not(.has-value)) {
  color: $magnetic-grey;
  top: -14px;
}

:deep(.v-input__details) {
  display: none;
}

.errors {
  p {
    @include errors;
  }
}

.spinner {
  margin-right: 0;
  height: 53vh;
}

.v-card {
  height: $v-card-height;
  overflow-y: auto;
}

span {
  font-family: $font-family-medium;
}

.modal {
  &__title {
    @include modal-title;
  }
  &__content {
    @include modal-content-column;

    > * {
      font-size: $font-size-m;
      font-weight: $font-weight-mid-bold;
    }

    &--label {
      margin-bottom: 0.5 * $spacer-sm;

      max-width: 4.5rem;
      &--readonly {
        pointer-events: none;
      }
    }

    &--clock {
      max-width: 14rem;

      :deep(.v-field__append-inner) {
        > img {
          width: $font-size-m;
          height: $font-size-m;
        }
      }
    }

    &--from {
      display: flex;
      flex-direction: row;
      width: 53%;

      > div:nth-child(2) {
        margin-left: $spacer-sm;
      }
    }

    &--title {
      margin-bottom: $spacer-sm;
      &-images {
        display: flex;
        flex-direction: row;
        align-items: center;

        > div {
          margin-left: $default-spacer * 2;
        }
      }
    }
  }
  &__actions {
    @include modal-actions;

    > button {
      margin: $spacer-sm 4 * $default-spacer;
    }
  }
}
</style>
