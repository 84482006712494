import type { TableHeadersConfiguration } from '@/models/table'

const tableConfigChallenges = {
  headers: [
    { title: 'Actions', value: 'draggable', empty: true },

    { title: 'No', value: 'no', empty: false },
    {
      title: 'Name',
      value: 'name',
      empty: false
    },
    { title: 'Description', value: 'description', empty: false },
    { title: 'Actions', value: 'actions', empty: true }
  ] as TableHeadersConfiguration[]
}

const tableConfigScoreboard = {
  headers: [
    { title: 'No', value: 'no', empty: false },
    {
      title: 'Name',
      value: 'Item1',
      empty: false
    },
    { title: 'Score', value: 'Item2', empty: false },
    { title: '', value: '', empty: true }
  ] as TableHeadersConfiguration[]
}

const tableConfigTeam = {
  headers: [
    { title: 'No', value: 'no', empty: false },
    {
      title: 'Name',
      value: 'Name',
      empty: false
    },
    { title: 'Upn', value: 'Upn', empty: false },
    { title: '', value: '', empty: true }
  ] as TableHeadersConfiguration[]
}

const tableConfigTeamWithActions = {
  headers: [
    { title: 'No', value: 'no', empty: false },
    {
      title: 'Name',
      value: 'name',
      empty: false
    },
    { title: 'Upn', value: 'upn', empty: false },
    { title: 'Actions', value: 'actions', empty: true }
  ] as TableHeadersConfiguration[]
}

const tableConfigUserEngagement = {
  headers: [
    { title: 'No', value: 'no', empty: false },
    {
      title: 'Challenge',
      value: 'challenge',
      empty: false
    },
    { title: 'Users that completed challenge', value: 'score', empty: false },
    { title: '', value: '', empty: true }
  ] as TableHeadersConfiguration[]
}

const tableConfigTeamEngagement = {
  headers: [
    { title: 'No', value: 'no', empty: false },
    {
      title: 'Challenge',
      value: 'challenge',
      empty: false
    },
    { title: 'Teams that completed challenge', value: 'score', empty: false },
    { title: '', value: '', empty: true }
  ] as TableHeadersConfiguration[]
}

const tableConfigTimeFrame = {
  headers: [
    { title: 'No', value: 'no', empty: false },
    {
      title: 'Time frame',
      value: 'frame',
      empty: false
    },
    { title: 'Challenges marked as done', value: 'score', empty: false },
    { title: '', value: '', empty: true }
  ] as TableHeadersConfiguration[]
}

const tableConfigAddTeam = {
  headers: [
    { title: 'No', value: 'no', empty: false },
    {
      title: 'Name',
      value: 'name',
      empty: false
    },
    { title: 'Upn', value: 'upn', empty: false },
    { title: 'Actions', value: 'actions', empty: true }
  ] as TableHeadersConfiguration[],
  data: []
}

const tableConfigAddEdition = {
  headers: [
    { title: 'No', value: 'no', empty: false },
    {
      title: 'Name',
      value: 'name',
      empty: false
    },
    { title: 'Description', value: 'description', empty: false },
    { title: 'Actions', value: 'actions', empty: true }
  ] as TableHeadersConfiguration[],
  data: []
}

export default {
  tableConfigChallenges,
  tableConfigScoreboard,
  tableConfigTeam,
  tableConfigTeamWithActions,
  tableConfigUserEngagement,
  tableConfigTeamEngagement,
  tableConfigTimeFrame,
  tableConfigAddTeam,
  tableConfigAddEdition
}
