<template>
  <v-dialog
    :model-value="props.modelValue"
    persistent
    width="1000"
    height="810"
    @click:outside="close"
  >
    <AppModalContent>
      <template #title>
        <div class="modal__title">
          <span>Create new challenge</span>
          <div @click="close" class="vf-icon-close vf-icon"></div>
        </div>
      </template>
      <template #content>
        <div class="modal__content">
          <AppInput
            class="modal__content--label modal__content--text"
            :label="'Name'"
            v-model="form.name"
            :disabled="loading"
            :validating="loading"
            placeholder="Name"
            :readonly="false"
            :type="'input'"
          ></AppInput>
          <AppValidationErrors
            v-if="v$.name.$error"
            :errors="v$.name.$errors"
          ></AppValidationErrors>
          <AppTextarea
            class="modal__content--label"
            :label="'Description'"
            v-model="form.description"
            :disabled="loading"
            :validating="loading"
            placeholder="Description"
            :readonly="false"
          ></AppTextarea>
          <AppValidationErrors
            v-if="v$.description.$error"
            :errors="v$.description.$errors"
          ></AppValidationErrors>
          <AppInput
            class="modal__content--label modal__content--link"
            :label="'Link'"
            v-model="form.link"
            :disabled="loading"
            :validating="loading"
            placeholder="Link"
            :readonly="false"
            :type="'input'"
          ></AppInput>
          <AppValidationErrors
            v-if="v$.link.$error"
            :errors="v$.link.$errors"
          ></AppValidationErrors>

          <div class="modal__content--title">
            <AppDragAndDropFile
              :active="form.files.length === 0"
              :multiple="false"
              :accept="'image/*'"
              :title="'Picture'"
              :draggable="false"
              :removeable="true"
              v-model="form.files"
            ></AppDragAndDropFile>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="modal__actions">
          <AppButton @on-click="process" :type="buttonType.PRIMARY" :label="'Save'"></AppButton>
          <AppButton @on-click="close" :type="buttonType.SECONDARY" :label="'Cancel'"></AppButton>
        </div>
      </template>
    </AppModalContent>
  </v-dialog>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'

import { required, helpers, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import Enums from '@/models/enums'
import { useChallengeConfigurationStore } from '@/stores/challengeConfigurationStore'
import DataHelper from '@/helpers/DataHelper'

const emit = defineEmits(['update:modelValue', 'process'])
const getInitialFormData = () => ({
  name: String() as string,
  description: String() as string,
  link: String() as string,
  files: [] as string[]
})

const validations = {
  name: {
    required: helpers.withMessage(Enums.FormMessages.REQUIRED, required),
    minLength: helpers.withMessage(Enums.FormMessages.MIN_LENGTH, minLength(3))
  },
  description: {
    required: helpers.withMessage(Enums.FormMessages.REQUIRED, required),
    minLength: helpers.withMessage(Enums.FormMessages.MIN_LENGTH, minLength(3))
  },
  link: {
    required: helpers.withMessage(Enums.FormMessages.REQUIRED, required),
    minLength: helpers.withMessage(Enums.FormMessages.MIN_LENGTH, minLength(3))
  }
}

const challengeConfigurationStore = useChallengeConfigurationStore()
const form = reactive(getInitialFormData())
const buttonType = Enums.ButtonEnum
const loading = ref(false)
const props = defineProps({
  modelValue: Boolean
})
const v$ = useVuelidate(validations, form)

function close() {
  emit('update:modelValue', false)
  Object.assign(form, getInitialFormData())
  v$.value.$reset()
}

function process() {
  v$.value.$validate()

  if (!v$.value.$error) {
    const whenToDisplay = DataHelper.setDateToDisplay(
      challengeConfigurationStore.challengeConfiguration,
      challengeConfigurationStore.challengeConfiguration.challenges.length
    )

    const formData = new FormData()
    formData.append('name', form.name)
    formData.append('description', form.description)
    formData.append('whenToDisplay', whenToDisplay)
    formData.append('link', form.link)
    formData.append('image', form.files.length > 0 ? form.files[0] : String())
    emit('process', formData)
    close()
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

:deep(.v-input__details) {
  display: none;
}

.v-card {
  height: $v-card-height;
  overflow-y: auto;
}

span {
  font-family: $font-family-medium;
}

.modal {
  &__title {
    @include modal-title;
  }
  &__content {
    @include modal-content-column;

    > * {
      font-size: $font-size-m;
      font-weight: $font-weight-mid-bold;
    }

    &--label {
      margin-bottom: 0.5 * $spacer-sm;
      &:nth-child(3) {
        margin-top: $spacer-sm;
      }
      &--readonly {
        pointer-events: none;
      }
    }

    &--text {
      max-width: 50%;
      width: 50%;
    }
    &--link {
      max-width: 60%;
      width: 60%;
    }

    &--title {
      margin-bottom: $spacer-sm;
      &-images {
        display: flex;
        flex-direction: row;
        align-items: center;

        > div {
          margin-left: $default-spacer * 2;
        }
      }
    }
  }
  &__actions {
    @include modal-actions;

    > button {
      margin: $spacer-sm 4 * $default-spacer;
    }
  }
}
</style>
