<template>
  <template v-if="data && data?.length > 0">
    <table id="table" class="vf-custom-table">
      <tr class="headers">
        <th scope="col" v-for="column in columns" :key="column.value">
          {{ hasTitle(column) }}
        </th>
      </tr>
      <draggable
        v-bind="dragOptions"
        :disabled="!disabled"
        :list="list"
        @start="onStart"
        @end="onEnd"
        @change="onUnpublishedChange"
        item-key="name"
        tag="tbody"
      >
        <template #item="{ element }">
          <tr class="tr" :class="disabled ? String() : 'disabled'">
            <td
              :class="disabled ? 'td__draggable' : ['td__disabled', 'td__img']"
              scope="row"
              :key="'draggable'"
            >
              <div v-if="showIcons">
                <img
                  :class="disabled ? String() : 'disabled'"
                  width="27px"
                  height="27px"
                  alt="Drag row icon"
                  src="../../assets/images/VF_drag_icon.svg"
                />
              </div>
            </td>
            <template v-for="column in columns" :key="column.value">
              <td
                :class="disabled ? 'td__draggable' : 'td__disabled'"
                scope="row"
                v-if="!column.empty"
              >
                {{ itemValue(element, column) }}
              </td>
            </template>

            <td
              v-if="showIcons"
              scope="row"
              :class="disabled ? 'td__draggable' : 'td__disabled'"
              :key="'actions'"
              class="td__actions"
            >
              <div>
                <div
                  @click="removeRow(element)"
                  :class="disabled ? String() : 'disabled'"
                  class="vf-icon-trash vf-icon"
                ></div>
                <div @click="editRow(element)">
                  <img
                    :class="disabled ? String() : 'disabled'"
                    width="25px"
                    height="25px"
                    alt="Edit row icon"
                    src="../../assets/images/VF_edit.svg"
                  />
                </div>
              </div>
            </td>
          </tr>
        </template>
      </draggable>
    </table>
  </template>
  <template v-else>
    <div class="vf-no-data">
      <span class="vf-no-data__title">
        {{ noItemsTitle }}
      </span>

      <span class="vf-no-data__label">
        {{ noItemsLabel }}
      </span>
    </div>
  </template>
</template>

<script lang="ts">
import { defineAsyncComponent, type PropType } from 'vue'

import type { TableHeadersConfiguration } from '@/models/table'

export default {
  components: {
    draggable: defineAsyncComponent(() => import('vuedraggable'))
  },
  props: {
    columns: Object as PropType<TableHeadersConfiguration[]>,
    data: Array as PropType<any>,
    disabled: Boolean,
    showIcons: Boolean,
    noItemsLabel: String,
    noItemsTitle: String
  },
  data() {
    return {
      drag: false
    }
  },
  computed: {
    list: {
      get() {
        return this.$props.data
      },
      set(value: any) {
        this.list = value
      }
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  emits: ['update-table', 'remove-row', 'edit-row'],
  methods: {
    hasTitle(column: any) {
      if (column.empty) {
        return 'Actions'
      } else {
        return column.title
      }
    },
    onStart() {
      this.drag = true
    },
    onEnd() {
      this.drag = false
    },
    itemValue(item: any, column: any) {
      return item[column.value]
    },
    onUnpublishedChange() {
      this.$emit('update-table', true, this.list)
    },
    removeRow(data: any) {
      this.$emit('remove-row', data)
    },
    editRow(data: any) {
      this.$emit('edit-row', data)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';
$icon-size: 1.6rem;
$row-height: 50px;

table {
  border-collapse: separate;
  border-spacing: 0;
}

.ghost {
  opacity: 0.5;
  background: $light-gray;
}

.vf-no-data {
  @include flex-wrap;
  flex-direction: column;
  align-items: center;
  font-family: $font-family-medium;
  margin: 3vh 0;

  > span {
    padding: 0 $spacer-sm;
    font-family: $font-family-medium;
    text-align: center;
  }

  &__title {
    @include no-data-title;
  }
  &__label {
    @include no-data-label;
    font-size: $font-size-m;
  }
}

.td {
  &__draggable {
    cursor: move;
    padding: $spacer-sm 0;
  }

  &__img {
    padding-bottom: 0.4rem !important;
  }

  &__disabled {
    cursor: unset;
    pointer-events: none;

    > div > img.disabled {
      filter: invert(0.8);
      cursor: pointer;
      pointer-events: none;
      margin-left: $spacer-sm;
      width: $icon-size + 0.2rem;
      height: $icon-size + 0.2rem;
    }
  }
}

.vf-custom-table {
  width: 100%;
  font-family: $font-family-medium;
  color: $coal-black;
  font-size: $font-size-m;
  line-height: $default-spacer;
  letter-spacing: -0.05px;
  background-color: $white;

  td {
    text-align: left;
    vertical-align: middle;
    padding: 0.25 * $spacer-sm 0.25 * $spacer-sm 0.25 * $spacer-sm 0;
    border-bottom: 1px solid rgba($coal-black, 0.2);

    &__draggable,
    &__actions {
      padding: $spacer-sm 0;
    }

    &:nth-child(2) {
      padding: $spacer-sm 2 * $spacer-sm $spacer-sm 0;
    }

    &:nth-child(4) {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 15rem;
    }
  }

  tr {
    vertical-align: baseline;
    height: $row-height;
  }

  th {
    @include vf-no-data-label;
    text-align: left;
    border-bottom: 1px solid rgba($coal-black, 0.2);
  }

  tr.headers {
    height: $row-height + 15px;
    position: sticky;
    top: 0px;
    z-index: $zindex-dropdown;
    background: $white;

    > th {
      vertical-align: middle;
    }

    > th:first-child {
      width: 5rem;
      padding: 0 $spacer-sm;
    }

    > th:first-child,
    :last-child {
      color: transparent;
    }
  }

  .tr {
    > .td {
      &__draggable {
        > div {
          display: flex;

          > img {
            margin-left: $spacer-sm;
            width: $icon-size + 0.2rem;
            height: $icon-size + 0.2rem;
          }
        }
      }

      &__actions {
        > div {
          display: flex;
          justify-content: end;
          margin-right: $default-spacer;

          > div::before {
            font-size: $icon-size;
          }

          > div {
            img {
              margin-left: $spacer-sm;
              cursor: pointer;
              width: $icon-size - 0.1rem;
              height: $icon-size - 0.1rem;

              &.disabled {
                filter: invert(0.9);
                cursor: pointer;
                pointer-events: none;
              }
            }
          }

          > div:first-child {
            color: $red;
            cursor: pointer;
            margin-right: $default-spacer;
          }

          > div:first-child.disabled {
            color: $light-gray;
            cursor: pointer;
            pointer-events: none;
          }
        }
      }
    }
  }
}
</style>
