<template>
  <div v-if="errors.length > 0" class="errors">
    <p v-for="(error, i) in errors" :key="i">{{ error.$message }}</p>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'

export default {
  props: {
    errors: {
      type: Array as PropType<any>
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.errors {
  p {
    @include errors;
  }
}
</style>
