import axios from 'axios'

import type { ChallengeConfiguration, ChallengeConfigurationList } from '@/models/challenges'
import { CONFIG } from '@/config'
import DataHelper from '@/helpers/DataHelper'

class ChallengeConfigurationService {
  private baseUrl = `${CONFIG.VITE_API_URL}`

  async fetchChallengesList(): Promise<ChallengeConfiguration[]> {
    return (
      await axios.get<ChallengeConfiguration[]>(
        `${this.baseUrl}/ChallengeConfigurations`,
        DataHelper.getAuthHeader()
      )
    ).data
  }

  async fetchEditionById(id: string): Promise<ChallengeConfiguration> {
    return (
      await axios.get(`${this.baseUrl}/ChallengeConfiguration?id=${id}`, DataHelper.getAuthHeader())
    ).data
  }

  async deleteChallengeConfigurationById(id: string): Promise<boolean> {
    return await axios.delete(
      `${this.baseUrl}/ChallengeConfiguration?id=${id}`,
      DataHelper.getAuthHeader()
    )
  }

  async addChallengeConfiguration(data: FormData) {
    return await axios.post(`${this.baseUrl}/ChallengeConfiguration`, data, {
      headers: {
        Authorization: DataHelper.getAuthHeader().headers.Authorization,
        'Content-Type': 'application/json'
      }
    })
  }

  async addChallengeConfigurationFromCopy(data: FormData) {
    return await axios.post(`${this.baseUrl}/ChallengeFromExistingConfiguration`, data, {
      headers: {
        Authorization: DataHelper.getAuthHeader().headers.Authorization,
        'Content-Type': 'application/json'
      }
    })
  }

  async editChallengeConfiguration(data: FormData) {
    return await axios.post(`${this.baseUrl}/EditChallengeConfiguration`, data, {
      headers: {
        Authorization: DataHelper.getAuthHeader().headers.Authorization,
        'Content-Type': 'application/json'
      }
    })
  }

  async checkChallengeConfigurationDateOccupation(data: {
    start: string
    end: string
  }): Promise<boolean> {
    return (
      await axios.post(
        `${this.baseUrl}/ValidateChallengeConfiguration`,
        data,
        DataHelper.getAuthHeader()
      )
    ).data
  }

  async checkEditChallengeConfigurationDateOccupation(data: {
    start: string
    end: string
    id: string
  }): Promise<boolean> {
    return (
      await axios.post(
        `${this.baseUrl}/EditValidateChallengeConfiguration`,
        data,
        DataHelper.getAuthHeader()
      )
    ).data
  }

  async editChallengeConfigurationChallenges(data: ChallengeConfigurationList): Promise<boolean> {
    return (
      await axios.post(
        `${this.baseUrl}/ChallengeConfiguration/ReOrder`,
        data,
        DataHelper.getAuthHeader()
      )
    ).data
  }
}

export default new ChallengeConfigurationService()
