<template>
  <v-dialog
    :model-value="props.modelValue"
    persistent
    width="1000"
    height="810"
    @click:outside="close"
  >
    <AppModalContent>
      <template #title>
        <div class="modal__title">
          <span>Create new edition</span>
          <div @click="close" class="vf-icon-close vf-icon"></div>
        </div>
      </template>
      <template #content>
        <template v-if="challengeConfigurationStore.challengeConfigurationFetchByIdLoading">
          <AppSpinner></AppSpinner>
        </template>
        <template v-else>
          <div class="modal__content">
            <div v-if="form && form.copiedEditionId" class="modal__content--from-existing">
              <div>
                <span> Create from existing one</span>
                <div>
                  <v-tooltip
                    location="end"
                    content-class="custom-tooltip"
                    class="modal__content--from-existing--tooltip"
                    text="After selecting this option,
                the copied edition will contain photos,
                starting time and list of challenges of the selected copied edition.
                To edit the edition created this way, please
                switch to the challenges view."
                  >
                    <template v-slot:activator="{ props }">
                      <div v-bind="props" class="vf-icon-information vf-icon"></div>
                    </template>
                  </v-tooltip>

                  <input type="checkbox" v-model="selected" class="vf-checkbox__input" />
                </div>
              </div>

              <template v-if="selected && form.copiedEditionId">
                <AppDropdown
                  class="modal__content--from-existing--dropdown"
                  :items="challengeConfigurationStore.challengeConfigurationsList"
                  v-model="form.copiedEditionId"
                  :placeholder="'Select edition'"
                ></AppDropdown>
              </template>
            </div>
            <AppInput
              class="modal__content--label"
              :label="'Name'"
              v-model="form.name"
              placeholder="Name"
              :readonly="false"
              :type="'input'"
            ></AppInput>
            <AppValidationErrors
              v-show="v$?.name?.$error"
              :errors="v$?.name?.$errors"
            ></AppValidationErrors>

            <div>
              <span class="app-label">Duration</span>
              <div class="modal__content--from">
                <AppDateInput
                  @update:modelValue="onDateSelection"
                  :label="'From'"
                  placeholder="Date from"
                  :formatProp="'dd.MM.yyyy'"
                  v-model="form.start"
                  :icon="calendarIconUrl"
                  :min="new Date(new Date().setDate(new Date().getDate() + 1))"
                >
                </AppDateInput>
                <AppInput
                  class="modal__content--label modal__content--label--readonly"
                  :label="'To'"
                  placeholder="Date to"
                  :changeLabel="true"
                  v-model="form.end"
                  :type="'text'"
                  :readonly="false"
                  :icon="calendarIconUrl"
                ></AppInput>
              </div>
              <AppValidationErrors
                v-show="v$?.start?.$error"
                :errors="v$?.start?.$errors"
              ></AppValidationErrors>
              <AppValidationErrors
                v-show="v$?.end?.$error"
                :errors="v$?.end?.$errors"
              ></AppValidationErrors>
              <div class="errors" v-show="showDatesOverlapping">
                <p>{{ Enums.FormMessages.DATE_OVERLAPPING }}</p>
              </div>
            </div>
            <AppTimeInput
              class="modal__content--clock"
              :label="'Start time'"
              :icon="clockIconUrl"
              v-model="form.hour"
            >
            </AppTimeInput>
            <AppValidationErrors
              v-show="v$?.hour?.$error"
              :errors="v$?.hour?.$errors"
            ></AppValidationErrors>
            <div class="modal__content--title">
              <AppDragAndDropFile
                :active="!selected"
                multiple
                :draggable="!selected"
                :removeable="!selected"
                :title="'Background images'"
                :accept="'image/*'"
                v-model="form.files"
              ></AppDragAndDropFile>
              <AppValidationErrors
                v-show="v$?.files?.$error"
                :errors="v$?.files?.$errors"
              ></AppValidationErrors>

              <div v-if="selected && form.files && form.files.length == 0">No images</div>
            </div>

            <template v-if="form.copiedEditionId && selected">
              <div>
                <div class="modal__content--title app-label">List of challenges</div>
                <div class="table_container">
                  <AppTableNondraggable
                    :data="DataHelper.updateNoInRow(form?.copiedTableData)"
                    :columns="tableConfig.headers"
                    :scoreboard="false"
                    :disabled="false"
                    :showIcons="false"
                    :noItemsTitle="'There are no challenges in this edition'"
                    :noItemsLabel="'Please add challenges after creating new edition'"
                  ></AppTableNondraggable>
                </div>
              </div>
            </template>
          </div>
        </template>
      </template>
      <template #actions>
        <div class="modal__actions">
          <AppButton @on-click="processData" :type="buttonType.PRIMARY" :label="'Save'"></AppButton>
          <AppButton @on-click="close" :type="buttonType.SECONDARY" :label="'Cancel'"></AppButton>
        </div>
      </template>
    </AppModalContent>
  </v-dialog>
</template>

<script setup lang="ts">
import { reactive, ref, watch } from 'vue'

import { required, helpers, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import Enums from '@/models/enums'
import tableConfigs from '@/models/table-configs'
import { useChallengeConfigurationStore } from '@/stores/challengeConfigurationStore'
import DataHelper from '@/helpers/DataHelper'
import ChallengeConfigurationService from '@/services/ChallengeConfigurationService'
import type { Challenge, ChallengeConfiguration, Time } from '@/models/challenges'
import calendarIconUrl from '@/assets/images/VF_calendar.svg'
import clockIconUrl from '@/assets/images/VF_clock.svg'

const emit = defineEmits(['update:modelValue', 'process'])
const getInitialFormData = () => ({
  name: String() as string,
  copiedEditionId: String() as string,
  copiedTableData: [] as Challenge[],
  start: {} as Date,
  end: String() as string,
  hour: { hours: 10, minutes: 0, seconds: 0 } as Time,
  files: [] as string[]
})

const getDefaultFormData = () => ({
  name: String() as string,
  copiedTableData: [] as Challenge[],
  start: {} as Date,
  end: String() as string,
  hour: { hours: 10, minutes: 0, seconds: 0 } as Time,
  files: [] as string[]
})

const challengeConfigurationStore = useChallengeConfigurationStore()
const showDatesOverlapping = ref(false)
const form = reactive(getInitialFormData())
const isDurationOccupied = (value: string) => {
  return ChallengeConfigurationService.checkChallengeConfigurationDateOccupation({
    start: new Date(value).toISOString(),
    end: new Date(form?.end).toISOString()
  }).then((res: boolean) => {
    showDatesOverlapping.value = !res
    return res
  })
}
const buttonType = Enums.ButtonEnum
const selected = ref(false)
const props = defineProps({
  modelValue: Boolean
})
const tableConfig = reactive(tableConfigs.tableConfigAddEdition)
const validations = {
  name: {
    required: helpers.withMessage(Enums.FormMessages.REQUIRED, required),
    minLength: helpers.withMessage(Enums.FormMessages.MIN_LENGTH, minLength(3))
  },
  start: {
    required: helpers.withMessage(Enums.FormMessages.REQUIRED, required),
    minValue(val: string) {
      return helpers.withMessage(
        Enums.FormMessages.DATE_AFTER_TODAY,
        () => new Date(val) > new Date()
      )
    },
    maxValue(val: Date, edition: ChallengeConfiguration) {
      return helpers.withMessage(
        Enums.FormMessages.DATE_OVERLAPPING_DATES,
        () => new Date(edition.start) > val
      )
    },
    asyncValidator: helpers.withAsync(isDurationOccupied)
  },
  end: {
    minValue(val: Date, edition: ChallengeConfiguration) {
      return helpers.withMessage(
        Enums.FormMessages.DATE_AFTER_START,
        () => val > new Date(edition.end)
      )
    }
  },
  hour: {
    required: helpers.withMessage(Enums.FormMessages.REQUIRED, required)
  },
  files: {
    required: helpers.withMessage(Enums.FormMessages.MIN_LENGTH_1, required)
  }
}
const v$ = useVuelidate(validations, form)

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue) {
      setDefaultValue()
    }
  }
)

watch(
  () => form.copiedEditionId,
  (newValue) => {
    if (newValue) {
      loadData()
    }
  }
)

watch(
  () => selected.value,
  () => {
    loadData()
  }
)

watch(
  () => challengeConfigurationStore.challengeConfigurationFetchByIdLoading,
  () => {
    loadEdition()
  }
)

function setDefaultValue() {
  resetData()
  form.copiedEditionId = challengeConfigurationStore.challengeConfigurationsList[0]?.id
}

function loadEdition() {
  if (!challengeConfigurationStore.challengeConfigurationFetchByIdLoading) {
    const copiedEdition = challengeConfigurationStore.challengeConfigurationById

    if (copiedEdition) {
      const timeZone = new Date(copiedEdition.start).getTimezoneOffset()

      form.copiedTableData = copiedEdition.challenges
      form.end = copiedEdition.end
      form.files = copiedEdition.images.map((item) => item.image)
      form.hour = {
        hours: new Date(copiedEdition.start).getHours() - timeZone / 60,
        minutes: new Date(copiedEdition.start).getMinutes(),
        seconds: 0
      } as Time
      form.name = copiedEdition.name
      form.start = new Date(copiedEdition.start)
    }
  }
}

function loadData() {
  if (selected.value) {
    if (
      challengeConfigurationStore.challengeConfigurationById &&
      challengeConfigurationStore.challengeConfigurationById.id === form.copiedEditionId
    ) {
      loadEdition()
    } else {
      challengeConfigurationStore.fetchChallengeConfigurationById(form.copiedEditionId)
    }
  } else {
    resetData()
  }
}

function close() {
  emit('update:modelValue', false)
}

function resetData() {
  showDatesOverlapping.value = false
  selected.value = false
  Object.assign(form, reactive(getDefaultFormData()))
  v$.value.$reset()
}

function processData() {
  v$.value.$validate()
  if (!v$.value.$error) {
    const start = new Date(form.start)
    start.setHours(form.hour.hours, form.hour.minutes)
    const formData = new FormData()

    if (selected.value) {
      formData.append('id', form.copiedEditionId)
      formData.append('name', form.name)
      formData.append('startDate', start.toISOString())
      formData.append('endDate', new Date(form.end).toISOString())
    } else {
      formData.append('name', form.name)
      formData.append('start', start.toISOString())
      formData.append('end', new Date(form.end).toISOString())
      form.files.forEach((element) => {
        formData.append('images[]', element)
      })
    }

    emit('process', formData, selected.value)
    close()
  }
}

function onDateSelection(value: Date) {
  const dateTo = DataHelper.addBusinessDaysToDate(value)
  form.end = DataHelper.formatDate(dateTo.toISOString(), 'yyyy-MM-dd')
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

:deep(input[type='date']:not(.has-value)) {
  color: $magnetic-grey;
  top: -14px;
}

:deep(.v-input__details) {
  display: none;
}

:global(.custom-tooltip) {
  max-width: 40% !important;
  height: auto !important;
}

.spinner {
  margin-right: 0;
}

input[type='checkbox'] {
  cursor: pointer;
  appearance: none;
  background-color: $white;
  margin: 0;
  font: inherit;
  color: $checkbox;
  width: 1.025rem;
  height: 1.025rem;
  border: 1px solid $checkbox;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  margin-left: 0.5rem;
}

input[type='checkbox']:checked::before {
  transform: scale(1);
}

input[type='checkbox']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em $coal-black;
  background-color: $coal-black;
  transform-origin: bottom left;
  clip-path: polygon(
    31.15% 66.32%,
    17.76% 48.56%,
    0% 61.95%,
    27.75% 98.75%,
    99.6% 27.12%,
    84.45% 11.91%
  );
}

.errors {
  p {
    @include errors;
  }
}
.table_container {
  max-height: 50vh;
  overflow-y: auto;
  margin-top: 8px;
}

.v-card {
  height: 100%;
  overflow-y: auto;
}

.app-label {
  font-family: $font-family-medium;
}

.modal {
  &__title {
    @include modal-title;
  }
  &__content {
    @include modal-content-column;

    > * {
      font-size: $font-size-m;
      font-weight: $font-weight-mid-bold;
      font-family: $font-family-medium;
    }

    &--from-existing {
      display: flex;
      flex-direction: column;
      width: 65%;

      &--dropdown {
        width: 60%;
      }

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-bottom: $spacer-sm;

        > span {
          margin-right: $spacer-sm;
          font-family: $font-family-medium;
        }

        > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 50%;

          > div:before {
            font-size: $font-size-m;
          }
          > input {
            height: $font-size-m;
            width: $font-size-m;
            margin-left: $spacer-sm;
          }

          .vf-checkbox__input::before {
            font-size: $font-size-m;
            font-weight: $font-weight-mid-bold;

            top: 0px;
            left: -1px;
          }

          .vf-checkbox__input:checked.vf-checkbox__input {
            &::before {
              font-size: $font-size-m;
              font-weight: $font-weight-mid-bold;

              color: $coal-black;
              top: 0px;
              left: -1px;
            }
          }
        }
      }
    }

    &--label {
      margin-bottom: 0.5 * $spacer-sm;
      max-width: 4.5rem;
      &--readonly {
        pointer-events: none;
      }
    }

    &--clock {
      max-width: 14rem;
      :deep(.v-field__append-inner) {
        > img {
          width: $font-size-m;
          height: $font-size-m;
        }
      }
    }

    &--from {
      display: flex;
      flex-direction: row;
      width: 53%;
      > div:nth-child(2) {
        margin-left: $spacer-sm;
      }
    }

    &--title {
      margin-bottom: $spacer-sm;
      &-images {
        display: flex;
        flex-direction: row;
        align-items: center;

        > div {
          margin-left: $default-spacer * 2;
        }
      }
    }
  }
  &__actions {
    @include modal-actions;

    > button {
      margin: $spacer-sm 4 * $default-spacer;
    }
  }
}
</style>
