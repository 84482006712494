import axios from 'axios'

import { CONFIG } from '@/config'
import type { Challenge } from '@/models/challenges'
import DataHelper from '@/helpers/DataHelper'

class ChallengeService {
  private baseUrl = `${CONFIG.VITE_API_URL}`

  async deleteChallengeById(id: string): Promise<boolean> {
    return await axios.delete(`${this.baseUrl}/Challenge?id=${id}`, DataHelper.getAuthHeader())
  }

  async getChallengeById(id: string): Promise<Challenge> {
    return (
      await axios.get(
        `${this.baseUrl}/Challenge/GetQuestionByID?id=${id}`,
        DataHelper.getAuthHeader()
      )
    ).data
  }

  async addChallenge(data: FormData, selectedEdition: string) {
    return await axios.post(
      `${this.baseUrl}/Challenge`,
      {
        name: data.get('name'),
        description: data.get('description'),
        challengeCampaignId: selectedEdition,
        link: data.get('link'),
        whenToDisplay: data.get('whenToDisplay'),
        image: {
          image: data.get('image')
        }
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: DataHelper.getAuthHeader().headers.Authorization
        }
      }
    )
  }

  async editChallenge(data: FormData, selectedChallenge: string) {
    return await axios.put(
      `${this.baseUrl}/Challenge`,
      {
        id: selectedChallenge,
        name: data.get('name'),
        description: data.get('description'),
        whenToDisplay: data.get('whenToDisplay'),
        link: data.get('link'),
        image: {
          id: '4fa85f64-5717-4562-b3fc-2c963f66afa6',
          image: data.get('image')
        }
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: DataHelper.getAuthHeader().headers.Authorization
        }
      }
    )
  }
}

export default new ChallengeService()
