import 'vuetify/styles'

import '@/assets/main.scss'
import '@/assets/base.scss'
import '@/styles/variables.scss'

import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import App from '@/App.vue'
import AppDropdown from '@/components/form/AppDropdown.vue'
import AppButton from '@/components/form/AppButton.vue'
import AppInput from '@/components/form/AppInput.vue'
import AppTextarea from '@/components/form/AppTextarea.vue'
import AppDateInput from '@/components/form/AppDateInput.vue'
import AppTimeInput from '@/components/form/AppTimeInput.vue'
import AppSpinner from '@/components/AppSpinner.vue'
import AppTable from '@/components/table/AppTable.vue'
import AppTableNondraggable from '@/components/table/AppTableNondraggable.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppAddEdition from '@/components/modals/editions/AppAddEdition.vue'
import AppEditEdition from '@/components/modals/editions/AppEditEdition.vue'
import AppModalContent from '@/components/modals/AppModalContent.vue'
import AppRemoveEdition from '@/components/modals/editions/AppRemoveEdition.vue'
import AppAddTeam from '@/components/modals/teams/AppAddTeam.vue'
import AppAddTeamMember from '@/components/modals/teams/AppAddTeamMember.vue'
import AppEditTeamMember from '@/components/modals/teams/AppEditTeamMember.vue'
import AppRemoveTeam from '@/components/modals/teams/AppRemoveTeam.vue'
import AppRemoveTeamMember from '@/components/modals/teams/AppRemoveTeamMember.vue'
import AppAddChallenge from '@/components/modals/challenges/AppAddChallenge.vue'
import AppRemoveChallenge from '@/components/modals/challenges/AppRemoveChallenge.vue'
import AppEditChallenge from '@/components/modals/challenges/AppEditChallenge.vue'
import AppDragAndDropFile from '@/components/dragAndDrop/AppDragAndDropFile.vue'
import AppFilePreview from '@/components/dragAndDrop/AppFilePreview.vue'
import AppValidationErrors from '@/components/form/AppValidationErrors.vue'

import { router } from '@/router'

const app = createApp(App)
const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  },
  components,
  directives
})

app.use(createPinia())
app.use(router)
app.use(vuetify)
app
  .component('AppDropdown', AppDropdown)
  .component('AppButton', AppButton)
  .component('AppInput', AppInput)
  .component('AppTextarea', AppTextarea)
  .component('AppDateInput', AppDateInput)
  .component('AppTimeInput', AppTimeInput)
  .component('AppTable', AppTable)
  .component('AppTableNondraggable', AppTableNondraggable)
  .component('AppSpinner', AppSpinner)
  .component('AppFooter', AppFooter)
  .component('AppEditEdition', AppEditEdition)
  .component('AppAddEdition', AppAddEdition)
  .component('AppModalContent', AppModalContent)
  .component('AppRemoveEdition', AppRemoveEdition)
  .component('AppAddChallenge', AppAddChallenge)
  .component('AppEditChallenge', AppEditChallenge)
  .component('AppRemoveChallenge', AppRemoveChallenge)
  .component('AppAddTeam', AppAddTeam)
  .component('AppAddTeamMember', AppAddTeamMember)
  .component('AppEditTeamMember', AppEditTeamMember)
  .component('AppRemoveTeam', AppRemoveTeam)
  .component('AppRemoveTeamMember', AppRemoveTeamMember)
  .component('AppDragAndDropFile', AppDragAndDropFile)
  .component('AppFilePreview', AppFilePreview)
  .component('AppValidationErrors', AppValidationErrors)

app.config.errorHandler = (error, instance, info) => {
  console.error('Global error:', error)
  console.info('Vue instance:', instance)
  console.error('Error info:', info)
}

app.mount('#app')
