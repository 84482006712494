<template>
  <Datepicker
    ref="timePicker"
    required
    v-model="time"
    time-picker
    :disabled="disabled"
    :enable-time-picker="true"
    disable-time-range-validation
    :placeholder="label"
    :start-time="time"
    :format="'HH:mm'"
    :config="{
      modeHeight: 180
    }"
    :clearable="false"
    :hide-navigation="['time', 'minutes', 'hours']"
    auto-apply
    minutes-increment="15"
    :action-row="{
      showSelect: false,
      showCancel: false,
      showNow: false,
      showPreview: false
    }"
  >
    <template #input-icon>
      <img
        :class="disabled ? 'input-slot-image-disabled' : ''"
        class="input-slot-image"
        :src="icon"
      />
    </template>
    <template #calendar-icon>
      <img :class="disabled ? 'input-slot-image-disabled' : ''" class="slot-icon" :src="icon" />
    </template>
  </Datepicker>
</template>

<script lang="ts">
import type { PropType } from 'vue'

import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import type { Time } from '@/models/challenges'

export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object as PropType<Time>,
      required: true
    },
    label: {
      type: String
    },
    icon: {
      type: String
    },
    disabled: {
      type: Boolean
    }
  },
  components: {
    Datepicker
  },
  computed: {
    time: {
      get() {
        return this.modelValue
      },
      set(value: Time) {
        this.selectedValue = value
        this.$emit('update:modelValue', value)
      }
    }
  },
  data: (vm) => ({
    selectedValue: {}
  })
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';
$position-tooltip: 0.25rem;
$font-size-tooltip: 0.6rem;
$input-message: 0.6rem;

:deep(.dp__cell_highlight) {
  background-color: $white;
  color: $light-gray;
  border-radius: 50%;
}

:deep(.dp__today) {
  background-color: $white;
  color: $coal-black;
  border: 1px solid $ocean-blue;
  border-radius: 50%;
}

:deep(.dp__cell_highlight_active) {
  background-color: $ocean-blue;
  color: $white;
  border-radius: 50%;
}

:deep(::placeholder) {
  color: $placeholder-gray;
  font-weight: $font-weight-mid-bold;
  font-family: $font-family-regular;
}

:deep(.dp__date_hover:hover) {
  background-color: $medium-grey;
  border-radius: 50%;
  transition: none;
}

:deep(.dp__input:hover) {
  background-color: $input-hover;
}

:deep(.dp__input) {
  font-family: $font-family-regular;
  font-size: $font-size-md;
  border: none;
  color: $coal-black;
  border-bottom: 2px solid $dropdown-grey;
  border-bottom-left-radius: 4px;
  top: -$input-message;
  border-bottom-right-radius: 4px;
  height: 2.8rem;

  padding-bottom: 0;
  padding-inline-start: 15px;
  margin-right: 0.5rem;
  padding-top: 1rem;
  padding-right: 0;
}

:deep(.dp__input_focus) {
  font-family: $font-family-regular;
  font-size: $font-size-md;
  border: none;
  border-bottom: 2px solid $ocean-blue;
  color: $coal-black;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 2.8rem;
}

:deep(.dp__cell_highlight:hover) {
  background-color: $white;
  color: $light-gray;
  border-radius: 50%;
}

:deep(.dp__input_icon) {
  top: 70%;
}

.input-slot-image {
  width: 17px;
  height: 17px;
  left: auto;
  right: -11.8rem;
  position: relative;
}

.slot-icon {
  width: 28px;
  height: 28px;
}

.readonly {
  pointer-events: none;
  cursor: pointer;
}

:deep(.dp__disabled) {
  color: $input-disabled;
  background-color: $white;
  border-bottom: none;
  background-image: linear-gradient(to right, $dropdown-grey 40%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 8.5px 2px;
  background-repeat: repeat-x;

  &:hover {
    background-color: $white;
  }
}

.input-slot-image-disabled {
  filter: invert(0.6);
  pointer-events: none;
  cursor: pointer;
}
</style>
