<template>
  <v-text-field
    data-testid="input-element"
    ref="input"
    color="primary"
    bg-color="white"
    :class="['input', 'custom-label-color', { readonly: readonly }]"
    :label="label"
    :readonly="readonly"
    :type="type"
    v-model="value"
    v-bind="attrsRest"
    :disabled="disabled"
    :min="min"
    :max="max"
    autocomplete="off"
    :placeholder="placeholder"
  >
    <template v-slot:append-inner>
      <img ref="input-icon" v-if="icon" width="28" height="28" :src="icon" />
    </template>
  </v-text-field>
</template>

<script lang="ts">
import DataHelper from '@/helpers/DataHelper'

export default {
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      required: true
    },
    validating: {
      type: Boolean
    },
    min: {
      type: String
    },
    max: {
      type: String
    },
    label: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    icon: {
      type: String
    },
    type: {
      type: String
    },
    readonly: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    hasError: {
      type: Boolean
    },
    changeLabel: {
      type: Boolean
    }
  },
  emits: ['update:modelValue'],
  setup(props, { attrs }) {
    const { class: attrsClassName, ...attrsRest } = attrs

    return {
      attrsClassName,
      attrsRest
    }
  },
  computed: {
    value: {
      get() {
        if (
          this.changeLabel &&
          this.modelValue &&
          (this.modelValue as string).length > 0 &&
          this.modelValue !== null
        ) {
          return DataHelper.formatDate(this.modelValue as string)
        } else {
          return this.modelValue
        }
      },
      set(value: string) {
        this.selectedItem = value
        this.$emit('update:modelValue', value)
      }
    }
  },
  data: (vm) => ({
    formatedItem: {},
    selectedItem: {}
  })
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
$position-tooltip: 0.25rem;
$font-size-tooltip: 0.6rem;
$input-message: 0.6rem;

.readonly {
  pointer-events: none;
  cursor: pointer;
}

.input {
  min-width: 15rem;
  padding-right: $spacer-sm;
  font-family: $font-family-regular;
  font-size: $font-size-md;

  :deep(.v-field__input) {
    font-size: $font-size-md;
    top: -$input-message;
    font-family: $font-family-regular;
  }

  :deep(.v-label.v-field-label--floating) {
    font-size: $font-size-tooltip;
    top: $position-tooltip;
    margin-left: $position-tooltip;
    font-family: $font-family-basic;
    color: $coal-black;
  }

  :deep(.v-label.v-field-label) {
    font-weight: $font-weight-mid-bold;
    font-family: $font-family-medium;
  }

  :deep(.v-input__control) {
    height: $input-height;
  }

  :deep(.v-field__outline) {
    color: $dropdown-grey;
    --v-field-border-width: 2px;
    --v-field-border-opacity: 1;
  }

  :deep(.v-field__outline::before) {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  :deep(.v-field--variant-filled) {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  :deep(.v-field--variant-focused) {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>
