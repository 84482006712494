<template>
  <button
    @click="$emit('onClick')"
    type="button"
    :class="{
      'vf-btn__secondary': type == buttonType.SECONDARY,
      'vf-btn__primary': type == buttonType.PRIMARY,
      'vf-btn__dark--outline': type === buttonType.DARK_OUTLINE,
      'vf-btn__secondary--outline': type === buttonType.BLUE_OUTLINE,
      'vf-red--outline': type === buttonType.RED_OUTLINE
    }"
    class="vf-btn"
  >
    {{ label }}
  </button>
</template>

<script lang="ts">
import Enums from '@/models/enums'

export default {
  data() {
    return {
      buttonType: Enums.ButtonEnum
    }
  },
  props: ['label', 'type']
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.vf-btn {
  font-family: $font-family-basic;
  font-weight: $font-weight-bold;
}

.vf-btn:not(.disabled) {
  font-family: $font-family-basic;
  font-weight: $font-weight-bold;
}

.vf-btn:disabled {
  background: $medium-grey;
  color: $white;
  font-family: $font-family-basic;
  font-weight: $font-weight-bold;
}

.vf-btn__secondary {
  background: $ocean-blue;
  color: $white;
}

.vf-btn__secondary--outline {
  color: $ocean-blue;
}

.vf-red--outline {
  background: $white;
  color: $red;
  border-color: $red;
}

@media (max-width: 685px) {
  button {
    margin: $spacer-sm 0;
  }
}
</style>
