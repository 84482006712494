<template>
  <v-dialog
    :model-value="props.modelValue"
    persistent
    width="1000"
    height="810"
    @click:outside="close"
  >
    <AppModalContent>
      <template #title>
        <div class="modal__title">
          <span>Create new team</span>
          <div @click="close" class="vf-icon-close vf-icon"></div>
        </div>
      </template>
      <template #content>
        <div class="modal__content">
          <AppInput
            class="modal__content--label"
            :label="'Team name'"
            v-model="form.name"
            :disabled="loading"
            :validating="loading"
            placeholder="Team name"
            :readonly="false"
            :type="'input'"
          ></AppInput>
          <AppValidationErrors
            v-if="v$.name.$error"
            :errors="v$.name.$errors"
          ></AppValidationErrors>

          <div class="modal__content--table">
            <span>List of team members</span>
            <AppButton
              @on-click="showAddTeamMember"
              :type="buttonType.SECONDARY"
              :label="'Add member'"
            ></AppButton>
            <template v-if="form.users && form.users.length > 0">
              <div class="table_container">
                <AppTableNondraggable
                  ref="tableComponentRef"
                  :data="form.users"
                  :columns="tableConfig.headers"
                  :showIcons="true"
                  :scoreboard="false"
                  :disabled="true"
                  @remove-row="removeMemberWithoutApi"
                  @edit-row="showUpdateTeamMember"
                  :noItemsTitle="'There are no team members in this team yet'"
                  :noItemsLabel="'Click \'Add member\' button to create first team member'"
                ></AppTableNondraggable>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="modal__actions">
          <AppButton @on-click="process" :type="buttonType.PRIMARY" :label="'Save'"></AppButton>
          <AppButton @on-click="close" :type="buttonType.SECONDARY" :label="'Cancel'"></AppButton>
        </div>
      </template>
    </AppModalContent>
  </v-dialog>

  <AppAddTeamMember @process="onAddTeamMember" v-model="showAddTeamMemberModal"></AppAddTeamMember>
  <AppEditTeamMember
    @process="onUpdateTeamMember"
    v-model="showUpdateTeamMemberModal"
    :selected-item="selectedRowItem"
  ></AppEditTeamMember>
</template>

<script setup lang="ts">
import { reactive, ref, watch } from 'vue'

import { required, helpers, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import Enums from '@/models/enums'
import tableConfigs from '@/models/table-configs'
import DataHelper from '@/helpers/DataHelper'
import type { Member } from '@/models/team'

const emit = defineEmits(['update:modelValue', 'process'])
const getInitialFormData = () => ({
  name: String() as string,
  users: [] as { no: string | number; name: string; upn: string }[]
})
const form = reactive(getInitialFormData())
const showAddTeamMemberModal = ref(false)
const showUpdateTeamMemberModal = ref(false)
const loading = ref(false)
const selectedRowItem = ref({})
const buttonType = Enums.ButtonEnum
const props = defineProps({
  modelValue: Boolean
})
const tableComponentRef = ref()
const tableConfig = tableConfigs.tableConfigAddTeam
const validations = {
  name: {
    required: helpers.withMessage(Enums.FormMessages.REQUIRED, required),
    minLength: helpers.withMessage(Enums.FormMessages.MIN_LENGTH, minLength(3))
  }
}
const v$ = useVuelidate(validations, form)
defineExpose({
  tableComponentRef
})

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue) {
      setDefaultValue()
    }
  }
)

function setDefaultValue() {
  resetData()
}

function resetData() {
  Object.assign(form, getInitialFormData())
  v$.value.$reset()
}

function showAddTeamMember() {
  showAddTeamMemberModal.value = !showAddTeamMemberModal.value
}

function showUpdateTeamMember(item: Member) {
  showUpdateTeamMemberModal.value = !showUpdateTeamMemberModal.value
  selectedRowItem.value = item
}

function removeMemberWithoutApi(item: { no: string | number; name: string; upn: string }) {
  const index = form.users.indexOf(item)
  if (index > -1) {
    form.users.splice(index, 1)
    form.users = DataHelper.updateNoInRow(form.users)
  }
}

function onAddTeamMember(data: FormData) {
  form.users.push({
    no: form.users.length + 1,
    name: data.get('name') as string,
    upn: data.get('upn') as string
  })

  showAddTeamMember()
}

function onUpdateTeamMember(data: { formData: FormData; rowNo: number }) {
  let rowItem = form.users.filter((item) => item.no == data.rowNo)[0]
  rowItem.name = data.formData.get('name') as string
  rowItem.upn = data.formData.get('upn') as string

  showUpdateTeamMember({} as Member)
}

function close() {
  emit('update:modelValue', false)
}

function process() {
  v$.value.$validate()

  if (!v$.value.name.$error) {
    const formData = new FormData()
    formData.append('name', form.name)
    form.users.forEach((element) => {
      formData.append(
        'users',
        JSON.stringify({
          name: element.name,
          upn: element.upn
        })
      )
    })

    emit('process', formData)
    // resetData()
    close()
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

:deep(.v-input__details) {
  display: none;
}

.errors {
  p {
    @include errors;
  }
}

.table_container {
  height: 13.125rem;
  overflow-y: auto;
  margin-top: 8px;
}

.v-card {
  height: 40rem;
  overflow-y: hidden;
}

span {
  font-family: $font-family-medium;
}

.modal {
  &__title {
    @include modal-title;
  }
  &__content {
    @include modal-content-column;
    > * {
      font-size: $font-size-m;
      font-weight: $font-weight-mid-bold;
    }

    &--label {
      margin-bottom: 0.5 * $spacer-sm;
      max-width: 4.5rem;
      &--readonly {
        pointer-events: none;
      }
    }

    &--table {
      margin-top: $spacer-sm * 1.5;
      display: flex;
      flex-direction: column;

      > button {
        margin-top: $spacer-sm;
        width: fit-content;
        margin-bottom: $spacer-sm;
      }

      > AppTable {
        width: fit-content;
      }
    }
  }
  &__actions {
    @include modal-actions;

    > button {
      margin: $spacer-sm 4 * $default-spacer;
    }
  }
}
</style>
