import { LogLevel, PublicClientApplication } from '@azure/msal-browser'

export const msalConfig = {
  auth: {
    clientId: 'ffa96517-ace6-45da-b66a-5fa956b75c3c',
    authority: 'https://login.microsoftonline.com/f8be18a6-f648-4a47-be73-86d6c5c6604d',
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    validateAuthority: false,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie:
      window.navigator.userAgent.indexOf('MSIE ') > -1 ||
      window.navigator.userAgent.indexOf('Trident/') > -1
  },

  system: {
    //TODO: uncomment when problems with auth
    // loggerOptions: {
    //   loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
    //     if (containsPii) {
    //       return
    //     }
    //     switch (level) {
    //       case LogLevel.Error:
    //         console.error(message)
    //         return
    //       case LogLevel.Info:
    //         console.info(message)
    //         return
    //       case LogLevel.Verbose:
    //         console.debug(message)
    //         return
    //       case LogLevel.Warning:
    //         console.warn(message)
    //         return
    //       default:
    //         return
    //     }
    //   },
    //   logLevel: LogLevel.Verbose
    // }
  }
}

export const msalInstance = new PublicClientApplication(msalConfig)

export const loginRequest = {
  scopes: ['api://ffa96517-ace6-45da-b66a-5fa956b75c3c/Data.All']
}

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
}
