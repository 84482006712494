import { defineStore } from 'pinia'

import type { ScoreboardDto } from '@/models/scoreboard'
import ScoreBoardService from '@/services/ScoreBoardService'
import DataHelper from '@/helpers/DataHelper'

export const useScoreBoardStore = defineStore({
  id: 'scoreBoardStore',
  state: () => {
    return {
      scoreboard: [] as ScoreboardDto[],
      scoreboardLoading: false,
      scoreboardError: null
    }
  },
  actions: {
    async fetchScoreBoardData(selectedEdition: string): Promise<void> {
      this.scoreboardLoading = true
      return ScoreBoardService.fetchScoreBoard(selectedEdition)
        .then((res) => {
          res = DataHelper.updateNoInRow(res)
          this.scoreboard = res || []
        })
        .catch((err) => {
          this.scoreboardLoading = false
          this.scoreboardError = err
        })
        .finally(() => {
          this.scoreboardLoading = false
        })
    }
  }
})
