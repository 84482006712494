<template>
  <header class="topbar">
    <div class="topbar__title-container flex-grow">
      <p class="topbar__title">Challenge Application Administrator Panel</p>
    </div>

    <div class="topbar__logo-container flex-grow">
      <img
        width="168px"
        height="56px"
        alt="Vattenfall's logo"
        src="../assets/images/VF_logo_linear_grey_RGB.svg"
      />
    </div>
    <div class="topbar__empty-container flex-grow"></div>
  </header>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';
$header-width: 168px;

.topbar {
  height: 3.5rem;
  @include flex-default;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  text-align: center;
  padding: 0 $spacer-sm;
  position: sticky;
  width: 100%;
  top: 0;
  box-shadow: $box-shadow-md-bottom;
  background: $white;
  z-index: $zindex-sticky;

  &__title-container {
    text-size-adjust: auto;
    justify-content: flex-start;
    flex-grow: 0;
  }
  &__title {
    font-size: $font-size-md;
    font-family: $font-family-medium;
    white-space: normal;
  }
  &__empty-container {
    display: none;
    flex-grow: 0;
  }
  &__logo-container {
    flex-grow: 0;
    display: none;

    > img {
      height: $bar-size;
      width: $header-width;
    }
  }
}

@media all and (min-width: $sizeLg) {
  .topbar {
    height: $bar-size;
    text-align: center;
    justify-content: space-between;

    > * {
      display: flex;
    }

    &__logo-container {
      margin: 0 auto;
      width: 542px;
    }

    &__title {
      font-size: $font-size-md;
      font-family: $font-family-medium;
      white-space: nowrap;
    }
  }
}
</style>
