import { defineStore } from 'pinia'

import ChallengeConfigurationService from '@/services/ChallengeConfigurationService'
import type { ChallengeConfiguration, ChallengeConfigurationList } from '@/models/challenges'
import DataHelper from '@/helpers/DataHelper'
import Enums from '@/models/enums'
import { useScoreBoardStore, useStatisticStore, useTeamStore } from '@/stores'

export const useChallengeConfigurationStore = defineStore({
  id: 'challengeConfigurationStore',
  state: () => {
    return {
      challengeConfigurationsList: [] as ChallengeConfiguration[],
      challengeConfiguration: {} as ChallengeConfiguration,
      challengeConfigurationById: {} as ChallengeConfiguration,
      currentChallengeConfiguration: {} as ChallengeConfiguration,
      copiedChallengeConfiguration: {} as ChallengeConfiguration,
      challengeConfigurationsListLoading: false,
      challengeConfigurationLoading: false,
      challengeConfigurationOperationLoading: false,
      challengeConfigurationFetchByIdLoading: false,
      challengeConfigurationsListError: null,
      challengeConfigurationOperationError: null,
      challengeConfigurationError: null
    }
  },
  getters: {
    getChallengesList: (state) => {
      return state.challengeConfigurationsList
    },
    getChallengesListWithoutFuture: (state) => {
      return DataHelper.filterFutureDates(
        state.currentChallengeConfiguration,
        state.challengeConfigurationsList
      )
    },
    getChallengeById: (state) => {
      return (challengeId: string) =>
        state.challengeConfigurationsList.find((list) => list.id === challengeId)
    },
    getSelectedChallenge: (state) => {
      return state.challengeConfiguration
    },
    getCurrentChallenge: (state) => {
      return state.currentChallengeConfiguration
    }
  },
  actions: {
    setSelectedChallenge(challengeId: string) {
      this.challengeConfiguration = this.challengeConfigurationsList.find(
        (list) => list.id === challengeId
      ) as ChallengeConfiguration
      this.copiedChallengeConfiguration = JSON.parse(JSON.stringify(this.challengeConfiguration))
    },
    setSelectedEditionChallenges() {
      this.challengeConfiguration.challenges = this.copiedChallengeConfiguration.challenges
    },
    copyChallenges() {
      this.copiedChallengeConfiguration = JSON.parse(JSON.stringify(this.challengeConfiguration))
    },
    async fetchChallengesList(page: string): Promise<void> {
      this.challengeConfigurationsListLoading = true
      const scoreBoardStore = useScoreBoardStore()
      const statisticStore = useStatisticStore()
      const teamStore = useTeamStore()

      return ChallengeConfigurationService.fetchChallengesList()
        .then((res) => {
          if (res && res.length > 0) {
            res = DataHelper.sortBySoonest(res)
            this.currentChallengeConfiguration = DataHelper.filterCurrentEdition(res)

            if (page === Enums.PageType.STATISTICS || page === Enums.PageType.SCOREBOARD) {
              res = DataHelper.filterFutureDates(this.currentChallengeConfiguration, res)
            }

            if (!this.currentChallengeConfiguration) {
              this.currentChallengeConfiguration = res[0]
            }

            const isEmpty =
              Object.keys(this.getSelectedChallenge).length === 0 &&
              this.getSelectedChallenge.constructor === Object

            if (isEmpty) {
              if (this.currentChallengeConfiguration) {
                this.challengeConfiguration = this.currentChallengeConfiguration
              } else {
                this.challengeConfiguration = {} as ChallengeConfiguration
              }
            } else {
              if (page === Enums.PageType.STATISTICS || page === Enums.PageType.SCOREBOARD) {
                if (DataHelper.checkIfExist(this.getSelectedChallenge, res)) {
                  this.challengeConfiguration = this.getSelectedChallenge
                } else {
                  if (this.currentChallengeConfiguration) {
                    this.challengeConfiguration = this.currentChallengeConfiguration
                  } else {
                    this.challengeConfiguration = {} as ChallengeConfiguration
                  }
                }
              } else {
                if (DataHelper.checkIfExist(this.getSelectedChallenge, res)) {
                  this.challengeConfiguration = this.getSelectedChallenge
                } else {
                  if (this.currentChallengeConfiguration) {
                    this.challengeConfiguration = this.currentChallengeConfiguration
                  } else {
                    this.challengeConfiguration = {} as ChallengeConfiguration
                  }
                }
              }
            }
          }
          res.forEach((element) => {
            element.challenges = DataHelper.updateNoInRow(element.challenges)
          })
          this.copiedChallengeConfiguration = JSON.parse(
            JSON.stringify(this.challengeConfiguration)
          )
          this.challengeConfigurationsList = res || []

          if (this.challengeConfiguration && this.challengeConfiguration.id) {
            switch (page) {
              case Enums.PageType.SCOREBOARD:
                scoreBoardStore.fetchScoreBoardData(this.challengeConfiguration.id)
                break
              case Enums.PageType.TEAMS:
                teamStore.fetchTeamsList(this.challengeConfiguration.id)
                break
              case Enums.PageType.STATISTICS:
                statisticStore.fetchTeamEngagement(this.challengeConfiguration.id)
                statisticStore.fetchUserEngagement(this.challengeConfiguration.id)
                statisticStore.fetchTimeFrames(this.challengeConfiguration.id)
                statisticStore.fetchNoOfParticipants(this.challengeConfiguration.id)
                statisticStore.fetchNoOfTeams(this.challengeConfiguration.id)
                statisticStore.fetchNoOfTeamsCompleted(this.challengeConfiguration.id)

                break

              default:
                break
            }
          }
        })
        .catch((err) => {
          this.challengeConfigurationsListLoading = false
          this.challengeConfigurationsListError = err
        })
        .finally(() => {
          this.challengeConfigurationsListLoading = false
        })
    },
    async fetchChallengesListWithSavedData(): Promise<void> {
      this.challengeConfigurationsListLoading = true

      return ChallengeConfigurationService.fetchChallengesList()
        .then((res) => {
          if (res && res.length > 0) {
            res = DataHelper.sortBySoonest(res)
            const isEmpty =
              Object.keys(this.getSelectedChallenge).length === 0 &&
              this.getSelectedChallenge.constructor === Object

            if (!isEmpty) {
              const selectedEdition = res.filter(
                (item) => item.id === this.getSelectedChallenge.id
              )[0]
              this.challengeConfiguration = selectedEdition
            } else {
              this.challengeConfiguration = DataHelper.filterCurrentEdition(res)
            }

            res.forEach((element) => {
              element.challenges = DataHelper.updateNoInRow(element.challenges)
            })
            this.copiedChallengeConfiguration = JSON.parse(
              JSON.stringify(this.challengeConfiguration)
            )
            this.challengeConfigurationsList = res || []
          }
        })
        .catch((err) => {
          this.challengeConfigurationsListLoading = false
          this.challengeConfigurationsListError = err
        })
        .finally(() => {
          this.challengeConfigurationsListLoading = false
        })
    },
    async removeChallengeConfigurationById(id: string): Promise<void> {
      this.challengeConfigurationOperationLoading = true

      return ChallengeConfigurationService.deleteChallengeConfigurationById(id)
        .then((res) => {
          if (res) {
            const idx = this.challengeConfigurationsList.findIndex((c) => c.id === id)
            if (idx === -1) return
            this.challengeConfigurationsList.splice(idx, 1)

            this.fetchChallengesList(Enums.PageType.CHALLENGES)
          }
        })
        .catch((err) => {
          this.challengeConfigurationOperationLoading = false
          this.challengeConfigurationOperationError = err
        })
        .finally(() => {
          this.challengeConfigurationOperationLoading = false
        })
    },
    async fetchChallengeConfigurationById(id: string): Promise<void> {
      this.challengeConfigurationFetchByIdLoading = true

      return ChallengeConfigurationService.fetchEditionById(id)
        .then((res) => {
          this.challengeConfigurationById = res
        })
        .catch((err) => {
          this.challengeConfigurationFetchByIdLoading = false
          this.challengeConfigurationOperationError = err
        })
        .finally(() => {
          this.challengeConfigurationFetchByIdLoading = false
        })
    },
    async addChallengeConfiguration(data: FormData): Promise<void> {
      this.challengeConfigurationOperationLoading = true

      return ChallengeConfigurationService.addChallengeConfiguration(data)
        .then(() => {
          this.fetchChallengesList(Enums.PageType.CHALLENGES)
        })
        .catch((err) => {
          this.challengeConfigurationOperationLoading = false
          this.challengeConfigurationOperationError = err
        })
        .finally(() => {
          this.challengeConfigurationOperationLoading = false
        })
    },
    async addChallengeConfigurationFromCopy(data: FormData): Promise<void> {
      this.challengeConfigurationOperationLoading = true

      return ChallengeConfigurationService.addChallengeConfigurationFromCopy(data)
        .then(() => {
          this.fetchChallengesList(Enums.PageType.CHALLENGES).finally(() => {
            const edition = this.getChallengesList.filter(
              (item) => item.name === data.get('name')
            )[0]
            const editionWithChallenges = DataHelper.setDateToDisplayForList(edition)
            const challengeConfiguration: ChallengeConfigurationList = {
              id: edition.id,
              challenges: editionWithChallenges.challenges.map((challenge) => {
                return {
                  id: challenge.id,
                  name: challenge.name,
                  description: challenge.description,
                  image: {
                    image: ''
                  },
                  link: challenge.link,
                  whenToDisplay: challenge.whenToDisplay
                }
              })
            }
            this.editChallengeConfigurationChallenges(challengeConfiguration)
          })
        })
        .catch((err) => {
          this.challengeConfigurationOperationLoading = false
          this.challengeConfigurationOperationError = err
        })
        .finally(() => {
          this.challengeConfigurationOperationLoading = false
        })
    },
    async editChallengeConfiguration(data: FormData): Promise<void> {
      this.challengeConfigurationOperationLoading = true

      return ChallengeConfigurationService.editChallengeConfiguration(data)
        .then(() => {
          const id = data.get('id') as string
          const edition = this.getChallengeById(id) as ChallengeConfiguration
          edition.start = data.get('start') as string
          const editionWithChallenges = DataHelper.setDateToDisplayForList(edition)
          const challengeConfiguration: ChallengeConfigurationList = {
            id: id,
            challenges: editionWithChallenges.challenges.map((challenge) => {
              return {
                id: challenge.id,
                name: challenge.name,
                description: challenge.description,
                image: {
                  image: ''
                },
                link: challenge.link,
                whenToDisplay: challenge.whenToDisplay
              }
            })
          }

          this.editChallengeConfigurationChallenges(challengeConfiguration).finally(() => {
            this.setSelectedChallenge(id as string)
            this.challengeConfigurationById = {} as ChallengeConfiguration
          })
        })
        .catch((err) => {
          this.challengeConfigurationOperationLoading = false
          this.challengeConfigurationOperationError = err
        })
        .finally(() => {
          this.challengeConfigurationOperationLoading = false
        })
    },
    async editChallengeConfigurationChallenges(data: ChallengeConfigurationList): Promise<void> {
      this.challengeConfigurationOperationLoading = true
      return ChallengeConfigurationService.editChallengeConfigurationChallenges(data)
        .then(() => {
          this.fetchChallengesList(Enums.PageType.CHALLENGES).finally(() => {
            const id = data.id
            this.setSelectedChallenge(id)
          })
        })
        .catch((err) => {
          this.challengeConfigurationOperationLoading = false
          this.challengeConfigurationOperationError = err
        })
        .finally(() => {
          this.challengeConfigurationOperationLoading = false
        })
    }
  }
})
