import { defineStore } from 'pinia'

export const useAuthStore = defineStore({
  id: 'authStore',
  state: () => {
    return {
      accessToken: String()
    }
  },
  getters: {
    getAccessToken: (state) => {
      return state.accessToken
    }
  },
  actions: {
    setAccessToken(token: string) {
      this.accessToken = token
    }
  }
})
