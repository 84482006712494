import { defineStore } from 'pinia'

import StatisticService from '@/services/StatisticService'
import DataHelper from '@/helpers/DataHelper'
import type {
  TeamEngagement,
  TeamEngagementChart,
  TimeFrames,
  TimeFramesChart,
  UserEngagement,
  PeakActivity,
  UserEngagementChart
} from '@/models/statistics'

export const useStatisticStore = defineStore({
  id: 'statisticStore',
  state: () => {
    return {
      numberOfParticipants: 0 as number,
      numberOfParticipantsTable: [] as UserEngagement[],
      numberOfTeamsTable: [] as TeamEngagement[],
      numberOfTeams: 0 as number,
      peakActivity: [] as PeakActivity[],
      timeFrames: [] as TimeFrames[],
      timeFramesChart: {} as TimeFramesChart,
      userEngagement: {} as UserEngagementChart,
      teamEngagement: {} as TeamEngagementChart,
      numberOfParticipantsLoading: false,
      numberOfTeamsLoading: false,
      timeFramesLoading: false,
      userEngagementLoading: false,
      teamEngagementLoading: false,
      numberOfParticipantsError: null,
      numberOfTeamsError: null,
      timeFramesError: null,
      userEngagementError: null,
      teamEngagementError: null
    }
  },
  actions: {
    async fetchTeamEngagement(selectedEdition: string): Promise<void> {
      this.teamEngagementLoading = true

      return StatisticService.fetchTeamEngagement(selectedEdition)
        .then((res) => {
          const data = DataHelper.updateNoInRow(
            Object.entries(res).map((item) => {
              return {
                challenge: item[0],
                score: item[1]
              }
            })
          )

          const chartData = {
            labels:
              data.map((item: TeamEngagement, index: number) => {
                return index + 1
              }) || [],
            data:
              data.map((item: TeamEngagement) => {
                return item.score
              }) || []
          }

          this.teamEngagement = chartData || []
        })
        .catch((err) => {
          this.teamEngagementLoading = false
          this.teamEngagementError = err
        })
        .finally(() => {
          this.teamEngagementLoading = false
        })
    },
    async fetchUserEngagement(selectedEdition: string): Promise<void> {
      this.userEngagementLoading = true

      return StatisticService.fetchUserEngagement(selectedEdition)
        .then((res) => {
          const data = DataHelper.updateNoInRow(
            Object.entries(res).map((item) => {
              return {
                challenge: item[0],
                score: item[1]
              }
            })
          )

          const chartData = {
            labels:
              data.map((item: UserEngagement, index: number) => {
                return index + 1
              }) || [],
            data:
              data.map((item: UserEngagement) => {
                return item.score
              }) || []
          }

          this.userEngagement = chartData || []
        })
        .catch((err) => {
          this.userEngagementLoading = false
          this.userEngagementError = err
        })
        .finally(() => {
          this.userEngagementLoading = false
        })
    },
    async fetchTimeFrames(selectedEdition: string): Promise<void> {
      this.timeFramesLoading = true

      return StatisticService.fetchTimeFrames(selectedEdition)
        .then((res) => {
          const peak = Object.entries(res).filter((item) => {
            return item[0] === '__peakActivity'
          })[0]

          const peakActivityArr = peak[1].split(',')
          const peakActivity = peakActivityArr.map((item) => {
            const splitted = item.split('-')
            if (splitted.length > 1) {
              return {
                from: splitted[0]?.concat('.00'),
                to: splitted[1]?.concat('.00')
              }
            }
          }) as PeakActivity[]

          const data =
            DataHelper.updateNoInRow(
              Object.entries(res)
                .filter((item) => item[0] !== '__peakActivity')
                .map((i) => {
                  return {
                    frame: i[0],
                    score: i[1],
                    peak: peakActivityArr.includes(i[0])
                  }
                })
            ) || ([] as TimeFrames[])

          this.timeFrames = JSON.parse(JSON.stringify(data))

          if (this.timeFrames && this.timeFrames.length > 0) {
            const chartData = data.sort((a: TimeFrames, b: TimeFrames) => {
              const itemA = a.frame.split('-')
              const itemB = b.frame.split('-')

              if (+itemA[0] < +itemB[0]) {
                return -1
              }
              if (+itemA[0] > +itemB[0]) {
                return 1
              }
              return 0
            })

            this.timeFramesChart = {
              labels:
                chartData.map((item: TimeFrames) => {
                  return item.frame
                }) || [],
              data:
                chartData.map((item: TimeFrames) => {
                  return item.score
                }) || []
            }
          } else {
            this.timeFramesChart = { labels: [], data: [] } as TimeFramesChart
          }

          this.peakActivity = peakActivity || []
        })
        .catch((err) => {
          this.timeFramesLoading = false
          this.timeFramesError = err
        })
        .finally(() => {
          this.timeFramesLoading = false
        })
    },
    async fetchNoOfParticipants(selectedEdition: string): Promise<void> {
      this.numberOfParticipantsLoading = true

      return StatisticService.fetchNoOfParticipants(selectedEdition)
        .then((res) => {
          this.numberOfParticipantsTable = DataHelper.updateNoInRow(
            Object.entries(res)
              .filter((item) => item[1].Name !== '__numberOfParticipants')
              .map((item) => {
                return {
                  challenge: item[1].Name,
                  score: item[1].Value
                }
              })
          )

          this.numberOfParticipants =
            Object.entries(res)
              .filter((item) => {
                return item[1].Name === '__numberOfParticipants'
              })
              .map((item) => +item[1].Value)[0] || 0
        })

        .catch((err) => {
          this.numberOfParticipantsLoading = false
          this.numberOfParticipantsError = err
        })
        .finally(() => {
          this.numberOfParticipantsLoading = false
        })
    },
    async fetchNoOfTeamsCompleted(selectedEdition: string): Promise<void> {
      this.numberOfTeamsLoading = true

      return StatisticService.fetchNoOfTeamsCompleted(selectedEdition)
        .then((res) => {
          this.numberOfTeamsTable = DataHelper.updateNoInRow(
            Object.entries(res)
              .filter((item) => item[0] !== '__numberOfTeams')
              .map((item) => {
                return {
                  challenge: item[0],
                  score: item[1]
                }
              })
          )
        })
        .catch((err) => {
          this.numberOfTeamsLoading = false
          this.numberOfTeamsError = err
        })
        .finally(() => {
          this.numberOfTeamsLoading = false
        })
    },
    async fetchNoOfTeams(selectedEdition: string): Promise<void> {
      this.numberOfTeamsLoading = true

      return StatisticService.fetchNoOfTeams()
        .then((res) => {
          this.numberOfTeams = Object.entries(res)
            .filter((item) => item[0].split('.')[0] === selectedEdition)
            .map((item) => +item[1])[0]
        })
        .catch((err) => {
          this.numberOfTeamsLoading = false
          this.numberOfTeamsError = err
        })
        .finally(() => {
          this.numberOfTeamsLoading = false
        })
    }
  }
})
