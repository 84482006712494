<template>
  <div>
    <div class="title-images">
      <span class="app-label">{{ title }}</span>
      <div>
        <label class="form-label">{{ label }}</label>
        <div v-if="active" :class="['file-input__container', { 'error bg-red-50': hasError }]">
          <input
            class="input-invisible"
            type="file"
            :accept="accept"
            :multiple="multiple"
            @change="handleChange"
          />
          <div class="text-center">
            <span class="app-label">Drag and drop or click to browse</span>
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
    <AppFilePreview
      ref="file-preview"
      :draggable="draggable"
      :removeable="removeable"
      :files="files"
      @on-image-remove="removeFile"
    >
    </AppFilePreview>
  </div>
</template>
<!-- Add if image reorder needed: @on-image-order-change="changeOrder" -->

<script setup lang="ts">
import { computed, type PropType } from 'vue'

import AppFilePreview from '@/components/dragAndDrop/AppFilePreview.vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  label: {
    type: String
  },
  title: {
    type: String
  },
  active: {
    type: Boolean
  },
  modelValue: {
    type: Array as PropType<string[]>,
    required: true
  },
  removeable: {
    type: Boolean,
    required: true
  },
  draggable: {
    type: Boolean,
    required: true
  },
  errors: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  multiple: {
    type: Boolean
  },
  accept: {
    type: String
  }
})

const hasError = computed(() => props.errors.length > 0)
const files = computed<string[]>({
  get(): string[] {
    return props.modelValue
  },
  set(files) {
    emit('update:modelValue', files)
  }
})

const removeFile = (element: string) => {
  const idx = files.value.findIndex((el) => el === element)
  if (idx !== -1) {
    files.value.splice(idx, 1)
  }
}

//Add if image reorder needed
// const changeOrder = (event: {
//   moved: {
//     newIndex: number
//     oldIndex: number
//   }
// }) => {
//   // files.value.splice(event.moved.newIndex, 0, files.value.splice(event.moved.oldIndex, 1)[0])
// }

function createBase64Image(fileObject: File) {
  const reader = new FileReader()

  reader.onload = (e) => {
    files.value.push(e.target?.result as string)
    files.value = checkForDuplicates(files.value)
  }
  reader.readAsDataURL(fileObject)
}

const checkForDuplicates = (arr: string[]) => {
  const indexes = arr.filter((item, index) => {
    if (arr.indexOf(item) !== index) {
      return index
    }
  })

  if (indexes && indexes.length > 0) {
    indexes.forEach((element) => {
      removeFile(element)
    })
  }

  return files.value
}

const handleChange = (e: Event | any) => {
  const fileList = e.target.files

  if (fileList) {
    for (let i = 0; i < fileList.length; i++) {
      createBase64Image(fileList[i])
    }
  }
}

defineExpose({
  removeFile,
  files,
  checkForDuplicates,
  handleChange,
  createBase64Image
})
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
$position-tooltip: 0.25rem;
$font-size-tooltip: 0.6rem;
$input-message: 0.6rem;

.title-images {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: $spacer-sm 0;

  > div {
    margin-left: $default-spacer * 2;
  }
}
.input-invisible {
  opacity: 0;
  width: max-content;
  height: max-content;
  position: absolute;
  cursor: pointer;
}

.app-label {
  font-family: $font-family-medium;
}

.readonly {
  pointer-events: none;
  cursor: pointer;
}

.file-input__container {
  position: relative;
  place-items: center;
  padding: $default-spacer;
  display: flex;
  width: 15rem;
  height: 4rem;
  border: 2px dashed $ocean-blue;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: $font-family-medium;

  > span {
    font-weight: $font-weight-normal;
    font-family: $font-family-medium;
  }
}
</style>
