import { defineStore } from 'pinia'

import ChallengeService from '@/services/ChallengeService'
import type { Challenge } from '@/models/challenges'
import { useChallengeConfigurationStore } from '@/stores'

export const useChallengeStore = defineStore({
  id: 'challengeStore',
  state: () => {
    return {
      challenge: {} as Challenge,
      challengeById: {} as Challenge,
      challengeLoading: false,
      challengeLoadingById: false,
      challengeError: null,
      challengeOperationLoading: false,
      challengeOperationError: null
    }
  },
  getters: {
    getSelectedChallenge: (state) => {
      return state.challenge
    }
  },
  actions: {
    setSelectedChallenge(challenge: Challenge) {
      this.challenge = challenge
    },
    async fetchChallengeById(): Promise<void> {
      this.challengeLoadingById = true

      return ChallengeService.getChallengeById(this.challenge.id)
        .then((res) => {
          this.challengeById = res
        })
        .catch((err) => {
          this.challengeLoadingById = false
          this.challengeError = err
        })
        .finally(() => {
          this.challengeLoadingById = false
        })
    },
    async addChallenge(data: FormData, selectedEdition: string): Promise<void> {
      this.challengeOperationLoading = true
      const challengeConfigurationStore = useChallengeConfigurationStore()

      return ChallengeService.addChallenge(data, selectedEdition)
        .then(() => {
          challengeConfigurationStore.fetchChallengesListWithSavedData()
        })
        .catch((err) => {
          this.challengeOperationLoading = false
          this.challengeOperationError = err
        })
        .finally(() => {
          this.challengeOperationLoading = false
        })
    },
    async editChallenge(data: FormData, challengeId: string): Promise<void> {
      this.challengeOperationLoading = true
      const challengeConfigurationStore = useChallengeConfigurationStore()

      return ChallengeService.editChallenge(data, challengeId)
        .then(() => {
          this.challengeById = {} as Challenge
          challengeConfigurationStore.fetchChallengesListWithSavedData()
        })
        .catch((err) => {
          this.challengeOperationLoading = false
          this.challengeOperationError = err
        })
        .finally(() => {
          this.challengeOperationLoading = false
        })
    },
    async removeChallengeById(id: string): Promise<void> {
      this.challengeOperationLoading = true
      const challengeConfigurationStore = useChallengeConfigurationStore()

      return ChallengeService.deleteChallengeById(id)
        .then(() => {
          challengeConfigurationStore.fetchChallengesListWithSavedData()
        })
        .catch((err) => {
          this.challengeOperationLoading = false
          this.challengeOperationError = err
        })
        .finally(() => {
          this.challengeOperationLoading = false
        })
    }
  }
})
