const ButtonEnum = Object.freeze({
  SECONDARY: 'secondary',
  PRIMARY: 'primary',
  DARK_OUTLINE: 'darkOutline',
  BLUE_OUTLINE: 'blueOutline',
  RED_OUTLINE: 'redOutline'
})

const ChallengeTypeEnum = Object.freeze({
  NORMAL: 'normal',
  QUESTION_STICKER: 'questionSticker',
  STATEMENT_STICKER: 'statementSticker'
})

const PageType = Object.freeze({
  CHALLENGES: 'challenges',
  TEAMS: 'teams',
  SCOREBOARD: 'scoreboard',
  STATISTICS: 'statistics'
})

const FormMessages = Object.freeze({
  REQUIRED: 'This field is required and must be filled in',
  MIN_LENGTH: 'This field has to be min 3 characters long',
  MIN_LENGTH_1: 'This field is required. Please select at least one file',
  DATE_AFTER_TODAY: 'This field has to be after today date',
  DATE_OVERLAPPING_DATES: 'The dates from - to cannot overlap',
  DATE_AFTER_START: 'This field has to be after start date',
  DATE_OVERLAPPING: 'New edition cannot last during existing one'
})

export default { ButtonEnum, ChallengeTypeEnum, FormMessages, PageType }
