import { type RouteRecordRaw } from 'vue-router'

export type AppRouteNames = 'challenges' | 'teams' | 'statistics' | 'scoreboard' | 'access-denied'

export const routesRecords: RouteRecordRaw[] = [
  {
    name: 'challenges' as AppRouteNames,
    path: '/',
    alias: '/challenges',
    component: () => import('@/views/ChallengesView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'teams' as AppRouteNames,
    path: '/teams',
    component: () => import('@/views/TeamsView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'statistics' as AppRouteNames,
    path: '/statistics',
    component: () => import('@/views/StatisticsView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'scoreboard' as AppRouteNames,
    path: '/scoreboard',
    component: () => import('@/views/ScoreboardView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'access-denied' as AppRouteNames,
    path: '/access-denied',
    component: () => import('@/views/AccessDeniedView.vue'),
    meta: {
      requiresAuth: false
    }
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }
]
