<template>
  <div class="spinner">
    <lottie-player
      src="https://unpkg.com/@vf-dds/vf-dds-vanilla/src/examples/indicators/spinner/lottie/spinner.json"
      background="transparent"
      speed="1"
      style="width: 120px; height: 120px"
      loop
      autoplay
    ></lottie-player>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  margin-right: 6.25 * $spacer-sm;
}

@media (max-width: 655px) {
  .spinner {
    margin-right: 0;
  }
}
</style>
