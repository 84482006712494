import { ref } from 'vue'

import type { AccountInfo, AuthenticationResult, SilentRequest } from '@azure/msal-browser'
import { BrowserAuthError, EventType, NavigationClient } from '@azure/msal-browser'

import { loginRequest, msalInstance } from '@/authConfig'
import { CONFIG } from '@/config'
import { useAuthStore } from '@/stores/authStore'

export type MaybeAccount = AccountInfo | null

export const initialized = ref(false)
export const account = ref<MaybeAccount>(null)
export const hasPermission = ref(false)
export const error = ref<string>()

export const Auth = {
  async initialize(client?: NavigationClient) {
    msalInstance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        msalInstance.setActiveAccount(event.payload.account)
        if (event.payload?.idTokenClaims) {
          const permission = event.payload.idTokenClaims?.roles?.includes(
            CONFIG.VITE_AZURE_NAME
          ) as boolean

          localStorage.setItem('HP', JSON.stringify(permission))
          hasPermission.value = permission

          if (hasPermission.value) {
            useAuthStore().setAccessToken(event.payload.accessToken)
          }
        }
        //TODO: uncomment when problems with auth
        // console.log(event.payload.accessToken)
        this.getToken()
      }
    })

    if (initialized.value === false) {
      await msalInstance.initialize().then(() => {
        initialized.value = true
      })

      await msalInstance.handleRedirectPromise()
      if (client) {
        msalInstance.setNavigationClient(client)
      }

      const accounts = msalInstance.getAllAccounts()
      if (accounts?.length) {
        this.setAccount(accounts[0])
      }

      account.value = msalInstance.getActiveAccount()
    }
  },

  async login() {
    msalInstance
      .loginRedirect()
      .then((result: any) => {
        //TODO: uncomment when problems with auth
        // console.log('Logged in with', result)
      })
      .catch((error: BrowserAuthError) => {
        if (error.errorCode === 'interaction_in_progress') {
          this.reset()
          this.login()
        }
        throw new Error(error.errorMessage)
      })
  },

  getHasPermission(): boolean {
    hasPermission.value = localStorage.getItem('HP')?.toLowerCase() === 'true'
    return hasPermission.value
  },

  async getToken() {
    const request: SilentRequest = loginRequest

    return msalInstance.acquireTokenSilent(request).then((result: AuthenticationResult) => {
      useAuthStore().setAccessToken(result.accessToken)
    })
  },

  setAccount(account: MaybeAccount): MaybeAccount {
    msalInstance.setActiveAccount(account)

    return account
  },

  reset() {
    localStorage.clear()
  }
}
