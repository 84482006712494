import { type AppRouteNames } from '@/router/routes'

export interface NavLink {
  id: number
  title: string
  url: AppRouteNames
  icon: string
}

export const navItems: NavLink[] = [
  {
    id: 1,
    title: 'Challenges',
    url: 'challenges',
    icon: 'vf-icon-check'
  },
  {
    id: 2,
    title: 'Teams',
    url: 'teams',
    icon: 'vf-icon-discussion-two'
  },
  {
    id: 3,
    title: 'Scoreboard',
    url: 'scoreboard',
    icon: 'vf-icon-list-view'
  },
  {
    id: 4,
    title: 'Statistics',
    url: 'statistics',
    icon: 'vf-icon-consumption'
  }
]
