<template>
  <div>
    <v-select
      ref="dropdown"
      class="vf-dropdown-wrapper vf-dropdown__standard vf-js-standard-dropdown"
      :items="items"
      item-value="id"
      v-model="value"
      variant="solo"
      density="comfortable"
      :placeholder="placeholder"
    >
      <template v-slot:selection="{ item }">
        <v-list-item-title>
          <div>
            <span class="item_title">{{ item.raw.name }} </span>
            <span class="item_title--date">
              {{ DataHelper.formatDate(item.raw.start) }} -
              {{ DataHelper.formatDate(item.raw.end) }}
            </span>
          </div>
        </v-list-item-title>
      </template>

      <template v-slot:item="{ item, props: { title, ...itemProps } }">
        <v-list-item v-bind="itemProps" :id="title">
          <div>
            <span class="item_title">{{ item.raw.name }} </span>
            <span class="item_title--date">
              {{ DataHelper.formatDate(item.raw.start) }} -
              {{ DataHelper.formatDate(item.raw.end) }}
            </span>
          </div>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script lang="ts">
import { type PropType } from 'vue'

import { type ChallengeConfiguration } from '@/models/challenges'
import DataHelper from '@/helpers/DataHelper'

export default {
  props: {
    items: Object as PropType<ChallengeConfiguration[]>,
    modelValue: String,
    placeholder: String
  },
  emits: ['update:modelValue'],
  setup() {
    return { DataHelper }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value: ChallengeConfiguration) {
        this.selectedItem = value
        this.$emit('update:modelValue', value)
      }
    }
  },
  data: (vm) => ({
    selectedItem: {}
  })
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
$dropdown-width: 25rem;

v-select {
  height: $input-height;
}

.vf-dropdown__standard {
  padding: 0;
  width: $dropdown-width;
  overflow: hidden;

  &:hover {
    background: $white;
    border-color: $light-gray;
    border-radius: 4px;
  }
}

.v-list-item-title > div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 90%;
}

:deep(.v-input) {
  border-radius: 6px;
  border: 2px solid $dropdown-grey;
}

:deep(.v-input:hover) {
  border-radius: 6px;
  border: 2px solid $dropdown-grey;
}

:deep(.v-field) {
  box-shadow: none;
}

.item_title {
  color: $coal-black;
  font-size: $font-size-md;
  font-family: $font-family-regular;
  padding-right: 1rem;
  &--date {
    color: $medium-grey;
    font-size: $font-size-sm;
    font-family: $font-family-basic;
  }
}

:deep(.mdi-menu-down) {
  display: none;
}
</style>
