<template>
  <footer class="footer">
    <slot> </slot>
  </footer>
</template>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

.footer {
  @include flex-default;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 0 $spacer-sm;
  box-shadow: $box-shadow-md-top;
  height: auto;
  background: $light-blue;
  z-index: $zindex-sticky;

  > * {
    margin: 0;
    width: auto;
    flex: 1 1 0;
  }
}

@media all and (min-width: $sizeMd) {
  .footer {
    height: $bar-size;
    > * {
      display: flex;
    }
  }
}
</style>
