<template>
  <v-card>
    <v-card-title>
      <slot name="title"></slot>
    </v-card-title>
    <v-card-text>
      <v-container>
        <slot name="content"> </slot>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
export default {}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.v-card-title {
  border-bottom: 1px solid $ash-blue;
}

.v-card-text {
  max-height: 40rem;
  overflow-y: auto;
  padding: 0 !important;
}

.v-container {
  font-family: $font-family-medium;
}

.v-card-actions {
  display: flex;
  justify-content: center;
  border-top: 1px solid $ash-blue;
  position: sticky;
  background: $white;
  bottom: 0;
}
</style>
