<template>
  <v-textarea
    ref="textarea"
    color="primary"
    :v-bind="value"
    :value="value"
    bg-color="white"
    :class="['input', 'custom-label-color', { readonly: readonly }]"
    :label="label"
    prefix=""
    :readonly="readonly"
    v-model="value"
    :disabled="disabled"
    rows="2"
    max-rows="5"
    :auto-grow="true"
    autocomplete="off"
    :placeholder="placeholder"
  >
  </v-textarea>
</template>

<script lang="ts">
export default {
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      required: true
    },
    validating: {
      type: Boolean
    },
    label: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    readonly: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    hasError: {
      type: Boolean
    }
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value: string) {
        this.selectedItem = value
        this.$emit('update:modelValue', value)
      }
    }
  },
  data: (vm) => ({
    selectedItem: {}
  })
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
$position-tooltip: 0.25rem;
$font-size-tooltip: 0.6rem;
$input-message: 0.6rem;
$input-text-field: 10.5rem;

.readonly {
  pointer-events: none;
  cursor: pointer;
}

:deep(.v-field__outline) {
  color: $dropdown-grey;
  --v-field-border-width: 2px;
  --v-field-border-opacity: 1;
}

:deep(.v-field__outline::before) {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

:deep(.v-field--variant-filled) {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

:deep(.v-field--variant-focused) {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.input {
  min-width: 15rem;
  padding-right: $spacer-sm;
  font-size: $font-size-md;
  font-family: $font-family-regular;

  :deep(.v-field__input) {
    font-size: $font-size-md;
    top: -$input-message;
    max-height: $input-text-field;
    font-family: $font-family-regular;
  }

  :deep(.v-label.v-field-label--floating) {
    font-size: $font-size-tooltip;
    top: $position-tooltip;
    margin-left: $position-tooltip;
    font-family: $font-family-basic;
    color: $coal-black;
  }

  :deep(.v-label.v-field-label) {
    font-weight: $font-weight-mid-bold;
    font-family: $font-family-medium;
  }

  :deep(.v-input__control) {
    width: 90%;
  }
}
</style>
