<template>
  <v-dialog
    :model-value="props.modelValue"
    persistent
    width="875"
    height="450"
    @click:outside="close"
  >
    <AppModalContent>
      <template #title>
        <div class="modal__title">
          <span>Add new member</span>
          <div @click="close" class="vf-icon-close vf-icon"></div>
        </div>
      </template>
      <template #content>
        <div class="modal__content">
          <div>
            <AppInput
              class="modal__content--label"
              :label="'Name'"
              v-model="form.name"
              :disabled="loading"
              :validating="loading"
              placeholder="Name"
              :readonly="false"
              :type="'input'"
            ></AppInput>
            <AppValidationErrors
              v-if="v$.name.$error"
              :errors="v$.name.$errors"
            ></AppValidationErrors>
          </div>
          <div>
            <AppInput
              class="modal__content--label"
              :label="'Upn'"
              v-model="form.upn"
              :disabled="loading"
              :validating="loading"
              placeholder="Upn"
              :readonly="false"
              :type="'input'"
            ></AppInput>
            <AppValidationErrors
              v-if="v$.upn.$error"
              :errors="v$.upn.$errors"
            ></AppValidationErrors>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="modal__actions">
          <AppButton @on-click="process" :type="buttonType.PRIMARY" :label="'Save'"></AppButton>
          <AppButton @on-click="close" :type="buttonType.SECONDARY" :label="'Cancel'"></AppButton>
        </div>
      </template>
    </AppModalContent>
  </v-dialog>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'

import { required, helpers, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import Enums from '@/models/enums'

const emit = defineEmits(['update:modelValue', 'process'])
const getInitialFormData = () => ({
  name: String() as string,
  upn: String() as string
})
const form = reactive(getInitialFormData())
const loading = ref(false)
const buttonType = Enums.ButtonEnum
const props = defineProps({
  modelValue: Boolean
})

const validations = {
  name: {
    required: helpers.withMessage(Enums.FormMessages.REQUIRED, required),
    minLength: helpers.withMessage(Enums.FormMessages.MIN_LENGTH, minLength(3))
  },
  upn: {
    required: helpers.withMessage(Enums.FormMessages.REQUIRED, required),
    minLength: helpers.withMessage(Enums.FormMessages.MIN_LENGTH, minLength(3))
  }
}
const v$ = useVuelidate(validations, form)

function close() {
  emit('update:modelValue', false)
  Object.assign(form, getInitialFormData())
  v$.value.$reset()
}

function process() {
  v$.value.$validate()

  if (!v$.value.$error) {
    const formData = new FormData()
    formData.append('name', form.name)
    formData.append('upn', form.upn)

    emit('process', formData)
    close()
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

:deep(.v-input__details) {
  display: none;
}

.errors {
  p {
    @include errors;
  }
}
.v-card {
  height: 40rem;
  overflow-y: auto;
}

.modal {
  &__title {
    @include modal-title;
  }
  &__content {
    @include modal-content-column;
    > div {
      margin-bottom: $spacer-sm * 0.5;
    }

    > * {
      font-size: $font-size-m;
      font-weight: $font-weight-mid-bold;
    }

    &--label {
      margin-bottom: 0.5 * $spacer-sm;
      max-width: 50%;
      &--readonly {
        pointer-events: none;
      }
    }
  }
  &__actions {
    @include modal-actions;

    > button {
      margin: $spacer-sm 4 * $default-spacer;
    }
  }
}
</style>
