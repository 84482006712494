import { defineStore } from 'pinia'
import type { AxiosResponse } from 'axios'

import type { Team } from '@/models/team'
import TeamService from '@/services/TeamService'

export const useTeamStore = defineStore({
  id: 'teamStore',
  state: () => {
    return {
      teamList: [] as Team[],
      team: {} as Team,
      teamListLoading: false,
      teamLoading: false,
      teamOperationLoading: false,
      teamListError: null,
      teamError: null,
      teamOperationError: null
    }
  },
  getters: {
    getTeamList: (state) => {
      return state.teamList
    }
  },
  actions: {
    async fetchTeamsList(selectedEdition: string): Promise<void> {
      this.teamListLoading = true

      return TeamService.fetchTeamList(selectedEdition)
        .then((res) => {
          const teams = res.map((item) => {
            return {
              ...item,
              teammates: item.teammates.map((member) => ({
                ...member,
                no: item.teammates.findIndex((i) => i.id === member.id) + 1
              }))
            }
          })
          this.teamList = teams || []
        })
        .catch((err) => {
          this.teamListLoading = false
          this.teamListError = err
        })
        .finally(() => {
          this.teamListLoading = false
        })
    },
    async fetchTeamById(selectedTeam: string): Promise<void> {
      this.teamLoading = true

      return TeamService.fetchTeamById(selectedTeam)
        .then((res) => {
          this.team = res || []
        })
        .catch((err) => {
          this.teamLoading = false
          this.teamError = err
        })
        .finally(() => {
          this.teamLoading = false
        })
    },
    async removeTeamById(id: string, editionId: string): Promise<void> {
      this.teamOperationLoading = true

      return TeamService.deleteTeamById(id)
        .then((res) => {
          if (res) {
            const idx = this.teamList.findIndex((c) => c.id === id)
            if (idx === -1) return
            this.teamList.splice(idx, 1)

            this.fetchTeamsList(editionId)
          }
        })
        .catch((err) => {
          this.teamOperationLoading = false
          this.teamOperationError = err
        })
        .finally(() => {
          this.teamOperationLoading = false
        })
    },
    async addTeam(data: FormData, selectedEdition: string): Promise<void> {
      this.teamOperationLoading = true

      return TeamService.addTeam(data, selectedEdition)
        .then((res: AxiosResponse) => {
          this.fetchTeamsList(selectedEdition)
        })
        .catch((err) => {
          this.teamOperationLoading = false
          this.teamOperationError = err
        })
        .finally(() => {
          this.teamOperationLoading = false
        })
    },
    async editTeam(data: FormData, editionId: string): Promise<void> {
      this.teamOperationLoading = true

      return TeamService.editTeam(data)
        .then((res: AxiosResponse) => {
          this.fetchTeamsList(editionId)
        })
        .catch((err) => {
          this.teamOperationLoading = false
          this.teamOperationError = err
        })
        .finally(() => {
          this.teamOperationLoading = false
        })
    }
  }
})
