<template>
  <draggable
    :list="files"
    :disabled="!draggable"
    item-key="title"
    class="container__draggable"
    @change="onImageOrderChange"
    @start="onStart"
    @end="onEnd"
  >
    <template #item="{ element }">
      <div ref="preview" class="container">
        <img
          v-if="draggable"
          class="drag_icon"
          width="27px"
          height="27px"
          alt="Drag row icon"
          src="../../assets/images/VF_drag_icon.svg"
        />"
        <img class="bg_image" :src="element" width="150" height="150" />
        <div @click="onImageRemove(element)" v-if="removeable" class="vf-icon-close vf-icon"></div>
      </div>
    </template>
  </draggable>
</template>

<script lang="ts">
import { defineAsyncComponent } from 'vue'

export default {
  name: 'HelloWorld',
  props: {
    files: Array,
    draggable: Boolean,
    removeable: Boolean
  },
  components: {
    draggable: defineAsyncComponent(() => import('vuedraggable'))
  },
  emits: ['onImageRemove', 'onImageOrderChange'],
  data() {
    return {
      drag: true
    }
  },
  methods: {
    onImageOrderChange(event: Object) {
      this.$emit('onImageOrderChange', event)
    },
    onImageRemove(item: Object) {
      this.$emit('onImageRemove', item)
    },
    onStart() {
      this.drag = true
    },
    onEnd() {
      this.drag = false
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
$icon-size: 1.7rem;
$width: 9.3rem;
$height: 11.3rem;

.container__draggable {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
}

.container {
  position: relative;
  margin-right: $spacer-sm;
  margin-bottom: $spacer-sm;

  width: $width;
  height: $height;
}

.drag_icon {
  height: $icon-size;
  width: $icon-size;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0.2 * $spacer-sm;
  z-index: $zindex-sticky;
  cursor: pointer;
}

.vf-icon:before {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  font-size: $font-size-m;
  margin: 0.5 * $spacer-sm;
  z-index: $zindex-sticky;
}

.bg_image {
  cursor: pointer;
  display: block;
  position: absolute;
  width: $width;
  top: 0;
  left: 0;
  height: $height;
}
</style>
