import { format } from 'date-fns'

import { CONFIG } from '@/config'
import type { Challenge, ChallengeConfiguration } from '@/models/challenges'
import { useAuthStore } from '@/stores/authStore'

class DataHelper {
  baseUrl = `${CONFIG.VITE_API_URL}`
  formatDate(date: string, dateFormat: string = 'dd.MM.yyyy'): string {
    return format(new Date(date), dateFormat)
  }

  getAuthHeader() {
    const accessToken = useAuthStore().getAccessToken
    return {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days)
    return date
  }

  setDateToDisplayForList(edition: ChallengeConfiguration): ChallengeConfiguration {
    edition.challenges.forEach((item, index) => {
      item.whenToDisplay = this.setDateToDisplay(edition, index)
    })

    return edition
  }

  setDateToDisplay(edition: ChallengeConfiguration, count: number): string {
    let newDate = new Date(new Date(edition.start).setHours(+new Date(edition.start).getHours()))
    newDate = new Date(new Date(newDate).setMinutes(new Date(edition.start).getMinutes() - 1))

    if (count === 0) {
      return newDate.toISOString()
    } else {
      for (let index = 0; index < count; index++) {
        newDate = this.addDays(newDate, 1)
        if (this.checkIfSaturday(newDate)) {
          newDate = this.addDays(newDate, 2)
        }
      }
      return newDate.toISOString()
    }
  }

  checkIfSaturday(date: Date): boolean {
    const day = date.getDay()
    return day === 6
  }

  checkDate(date: string): boolean {
    return new Date(new Date(date).toDateString()) > new Date(new Date().toDateString())
  }

  checkIfExist(date: ChallengeConfiguration, dates: ChallengeConfiguration[]): boolean {
    return dates.filter((e: ChallengeConfiguration) => e.id === date.id).length > 0
  }

  filterFutureDates(
    date: ChallengeConfiguration | any,
    dates: ChallengeConfiguration[]
  ): ChallengeConfiguration[] {
    const isEmpty =
      date === undefined || (Object.keys(date).length === 0 && date?.constructor === Object)
    const isFutureDate =
      +new Date(date?.start).setHours(0, 0, 0, 0) - +new Date().setHours(0, 0, 0, 0) <= 0
    const selectedDate = isEmpty || !isFutureDate ? new Date() : new Date(date?.end)

    const beforeDates = dates.filter(function (d: ChallengeConfiguration) {
      return +new Date(d.end) - +selectedDate <= 0
    })
    return beforeDates
  }

  filterCurrentEdition(dates: ChallengeConfiguration[]): ChallengeConfiguration {
    return dates.filter((element: ChallengeConfiguration, index: number) => {
      if (this.checkDatesByEdition(element.end, element.start)) {
        return dates[index]
      }
    })[0]
  }

  checkDatesByEdition(dateTo: string, dateFrom: string): boolean {
    const today = new Date().setHours(0, 0, 0, 0)
    const from = new Date(dateFrom).setHours(0, 0, 0, 0)
    const to = new Date(dateTo).setHours(0, 0, 0, 0)

    return today >= from && today <= to
  }

  sortBySoonest(dates: ChallengeConfiguration[]): ChallengeConfiguration[] {
    dates.sort(function (a: ChallengeConfiguration, b: ChallengeConfiguration) {
      return +new Date(b.start) - +new Date(a.start)
    })

    dates.forEach((element: ChallengeConfiguration, index: number) => {
      if (this.checkDatesByEdition(element.end, element.start)) {
        const item = dates.splice(index, 1)
        dates.splice(0, 0, item[0])
      }
    })

    dates.forEach((element: ChallengeConfiguration) => {
      element?.challenges?.sort(function (a: Challenge, b: Challenge) {
        return +new Date(a?.whenToDisplay) - +new Date(b?.whenToDisplay)
      })
    })

    return dates
  }

  checkIfWeekday(date: string): boolean {
    const day = new Date(date).getDay()
    return day === 6 || day === 0
  }

  updateNoInRow(data: any) {
    for (let index = 0; index < data.length; index++) {
      const number = index + 1
      data[index].no = number
    }

    return data
  }

  addBusinessDaysToDate(date: Date, days: number = 19): Date {
    const day = date.getDay()

    date = new Date(date.getTime())
    date.setDate(
      date.getDate() +
        days +
        (day === 6 ? 2 : +!day) +
        Math.floor((days - 1 + (day % 6 || 1)) / 5) * 2
    )
    return date
  }
}

export default new DataHelper()
