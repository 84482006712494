import axios from 'axios'

import { CONFIG } from '@/config'
import type { ScoreboardDto } from '@/models/scoreboard'
import DataHelper from '@/helpers/DataHelper'

class ScoreBoardService {
  private baseUrl = `${CONFIG.VITE_API_URL}`

  async fetchScoreBoard(id: string): Promise<ScoreboardDto[]> {
    return (
      await axios.get<ScoreboardDto[]>(
        `${this.baseUrl}/Scoreboard/Admin?editionId=${id}`,
        DataHelper.getAuthHeader()
      )
    ).data
  }
}

export default new ScoreBoardService()
