<template>
  <template v-if="data && data?.length > 0">
    <table class="vf-custom-table" :class="className">
      <tr class="headers">
        <th scope="col" v-for="column in columns" :key="column.value">
          {{ column.title }}
        </th>
      </tr>
      <tr
        @click="clickRow(d)"
        class="tr"
        :class="disabled ? String() : 'disabled'"
        v-for="d in data"
        :key="d"
      >
        <template v-for="column in columns" :key="column.value">
          <td
            scope="row"
            :class="d.peak ? 'color-row' : String()"
            v-if="!column.empty"
            class="is_column_empty"
          >
            {{ itemValue(d, column) }}
          </td>
        </template>
        <td scope="row" :key="'actions'" class="td__actions">
          <div v-if="showIcons" class="no_show_icons">
            <div
              @click="removeRow(d)"
              :class="disabled ? String() : 'disabled'"
              class="vf-icon-trash vf-icon icon"
            ></div>
            <div @click="editRow(d)">
              <img
                :class="disabled ? String() : 'disabled'"
                width="25px"
                class="icon"
                height="25px"
                alt="Edit row icon"
                src="../../assets/images/VF_edit.svg"
              />
            </div>
          </div>
        </td>
      </tr>
    </table>
  </template>
  <template v-else>
    <div class="vf-no-data">
      <span class="vf-no-data__title">
        {{ noItemsTitle }}
      </span>

      <span class="vf-no-data__label">
        {{ noItemsLabel }}
      </span>
    </div>
  </template>
</template>

<script lang="ts">
import type { PropType } from 'vue'

import type { TableHeadersConfiguration } from '@/models/table'

export default {
  props: {
    columns: Object as PropType<TableHeadersConfiguration[]>,
    data: Array as PropType<any>,
    disabled: Boolean,
    className: String,
    showIcons: Boolean,
    noItemsLabel: String,
    noItemsTitle: String
  },
  emits: ['update-table', 'remove-row', 'edit-row', 'click-row'],
  methods: {
    itemValue(item: any, column: TableHeadersConfiguration) {
      return item[column.value]
    },
    removeRow(data: any) {
      this.$emit('remove-row', data)
    },
    editRow(data: any) {
      this.$emit('edit-row', data)
    },
    clickRow(data: any) {
      this.$emit('click-row', data)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';
$icon-size: 1.6rem;
$row-height: 50px;

table {
  border-collapse: separate;
  border-spacing: 0;
}

.color-row {
  background: $light-blue;
}

.icon {
  cursor: pointer;
}

.vf-no-data {
  @include flex-wrap;
  flex-direction: column;
  align-items: center;
  font-family: $font-family-medium;
  margin: 3vh 0;

  > span {
    padding: 0 $spacer-sm;
    font-family: $font-family-medium;
    text-align: center;
  }

  &__title {
    @include no-data-title;
  }
  &__label {
    @include no-data-label;
    font-size: $font-size-m;
  }
}

.vf-custom-table.scoreboard > .tr {
  cursor: pointer;
  &:nth-child(2) {
    background: $sollor-yellow-50;
  }

  &:nth-child(3) {
    background: $sollor-yellow-30;
  }
  &:nth-child(4) {
    background: $sollor-yellow-10;
  }
}

.vf-custom-table.statistics {
  > .headers > th {
    &:first-child {
      width: min-content;
    }

    &:nth-child(2) {
      width: max-content;
    }
    &:nth-child(3) {
      text-align: center;
      width: min-content;
    }
  }
  > .tr > td {
    &:first-child {
      width: min-content;
    }

    &:nth-child(2) {
      width: max-content;
    }
    &:nth-child(3) {
      text-align: center;
      width: min-content;
    }
  }
}

.vf-custom-table {
  width: 100%;
  font-family: $font-family-medium;
  color: $coal-black;
  font-size: $font-size-m;
  line-height: $default-spacer;
  letter-spacing: -0.05px;
  background-color: $white;

  td {
    text-align: left;
    vertical-align: middle;
    padding: $spacer-sm 0;
    border-bottom: 1px solid rgba($coal-black, 0.2);

    &:first-child {
      padding: 0 $spacer-sm * 1.5;
    }

    &:nth-child(3) {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 15rem;
    }
  }

  tr {
    vertical-align: baseline;
    height: $row-height;
    border-bottom: 1px solid rgba($coal-black, 0.2);
  }

  th {
    text-align: left;
    @include vf-no-data-label;
    border-bottom: 1px solid rgba($coal-black, 0.2);
  }

  tr.headers {
    height: $row-height + 15px;
    position: sticky;
    top: 0px;
    z-index: $zindex-dropdown;
    background: $white;

    > th {
      vertical-align: middle;
      font-weight: $font-weight-mid-bold;
    }

    > th:first-child {
      width: 4rem;
      padding: 0 $spacer-sm;
    }

    > th:last-child {
      color: transparent;
    }
  }

  .tr {
    > .td {
      &__actions {
        > div {
          display: flex;
          justify-content: end;
          margin-right: $default-spacer;

          > div::before {
            font-size: $icon-size;
          }

          > div {
            img {
              margin-left: $spacer-sm;
              width: $icon-size - 0.1rem;
              height: $icon-size - 0.1rem;

              &.disabled {
                filter: invert(0.9);
                cursor: pointer;
                pointer-events: none;
              }
            }
          }

          > div:first-child {
            color: $red;
            margin-right: $default-spacer;
          }

          > div:first-child.disabled {
            color: $light-gray;
            cursor: pointer;
            pointer-events: none;
          }
        }
      }
    }
  }
}
</style>
