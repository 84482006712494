import axios from 'axios'

import { CONFIG } from '@/config'
import type { NoOfParticipantsDto, Team } from '@/models/team'
import DataHelper from '@/helpers/DataHelper'

class StatisticService {
  private baseUrl = `${CONFIG.VITE_API_URL}`

  async fetchTeamEngagement(id: string): Promise<Team[]> {
    return (
      await axios.get<Team[]>(
        `${this.baseUrl}/Statistics/PercentOfTeamsThatCompletedChallenge?editionId=${id}`,
        DataHelper.getAuthHeader()
      )
    ).data
  }

  async fetchUserEngagement(id: string): Promise<string> {
    return (
      await axios.get<string>(
        `${this.baseUrl}/Statistics/PercentOfUsersThatCompletedChallenge?editionId=${id}`,
        DataHelper.getAuthHeader()
      )
    ).data
  }

  async fetchTimeFrames(id: string): Promise<string> {
    return (
      await axios.get<string>(
        `${this.baseUrl}/Statistics/ChallengesCompletedInTimeFrame?editionId=${id}`,
        DataHelper.getAuthHeader()
      )
    ).data
  }

  async fetchNoOfParticipants(id: string): Promise<NoOfParticipantsDto[]> {
    return (
      await axios.get<NoOfParticipantsDto[]>(
        `${this.baseUrl}/Statistics/NumberOfParticipants?editionId=${id}`,
        DataHelper.getAuthHeader()
      )
    ).data
  }

  async fetchNoOfTeams(): Promise<string> {
    return (
      await axios.get<string>(
        `${this.baseUrl}/Statistics/NumberOfTeams`,
        DataHelper.getAuthHeader()
      )
    ).data
  }

  async fetchNoOfTeamsCompleted(id: string): Promise<string> {
    return (
      await axios.get<string>(
        `${this.baseUrl}/Statistics/NumberOfTeamsCompleted?editionId=${id}`,
        DataHelper.getAuthHeader()
      )
    ).data
  }
}

export default new StatisticService()
